@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40.0625em&large=48.0625em&xlarge=65.0625em&xxlarge=81.25em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "TTNorms", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 81.25rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    margin-right: -1.875rem;
    margin-left: -1.875rem; }
    @media print, screen and (min-width: 40.0625em) {
      .row .row {
        margin-right: -1.3125rem;
        margin-left: -1.3125rem; } }
    @media print, screen and (min-width: 48.0625em) {
      .row .row {
        margin-right: -1.3125rem;
        margin-left: -1.3125rem; } }
    @media screen and (min-width: 65.0625em) {
      .row .row {
        margin-right: -1rem;
        margin-left: -1rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns, body.no-flexbox .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row,
  body.no-flexbox .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns, body.no-flexbox .columns {
  flex: 1 1 0px;
  padding-right: 1.875rem;
  padding-left: 1.875rem;
  min-width: 0; }
  @media print, screen and (min-width: 40.0625em) {
    .column, .columns, body.no-flexbox .columns {
      padding-right: 1.3125rem;
      padding-left: 1.3125rem; } }
  @media screen and (min-width: 65.0625em) {
    .column, .columns, body.no-flexbox .columns {
      padding-right: 1rem;
      padding-left: 1rem; } }
  @media screen and (min-width: 81.25em) {
    .column, .columns, body.no-flexbox .columns {
      padding-right: 1.125rem;
      padding-left: 1.125rem; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-1 {
  margin-left: 16.66667%; }

.small-3 {
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-2 {
  margin-left: 33.33333%; }

.small-4 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-3 {
  margin-left: 50%; }

.small-5 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-4 {
  margin-left: 66.66667%; }

.small-6 {
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-5 {
  margin-left: 83.33333%; }

.small-up-1 {
  flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns, body.no-flexbox .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns, body.no-flexbox .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns, body.no-flexbox .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns, body.no-flexbox .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns, body.no-flexbox .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns, body.no-flexbox .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns, body.no-flexbox .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns, body.no-flexbox .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns, body.no-flexbox .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns, body.no-flexbox .small-uncollapse > .columns {
  padding-right: 1.875rem;
  padding-left: 1.875rem; }

@media print, screen and (min-width: 40.0625em) {
  .medium-1 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-1 {
    margin-left: 16.66667%; }
  .medium-3 {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-2 {
    margin-left: 33.33333%; }
  .medium-4 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-3 {
    margin-left: 50%; }
  .medium-5 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-4 {
    margin-left: 66.66667%; }
  .medium-6 {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-5 {
    margin-left: 83.33333%; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns, body.no-flexbox .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns, body.no-flexbox .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns, body.no-flexbox .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns, body.no-flexbox .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns, body.no-flexbox .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns, body.no-flexbox .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns, body.no-flexbox .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns, body.no-flexbox .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 40.0625em) and (min-width: 40.0625em) {
  .medium-expand {
    flex: 1 1 0px; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns, body.no-flexbox .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 40.0625em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns, body.no-flexbox .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 40.0625em) {
  .medium-collapse > .column, .medium-collapse > .columns, body.no-flexbox .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns, body.no-flexbox .medium-uncollapse > .columns {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem; } }

@media print, screen and (min-width: 48.0625em) {
  .large-1 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-1 {
    margin-left: 16.66667%; }
  .large-3 {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-2 {
    margin-left: 33.33333%; }
  .large-4 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-3 {
    margin-left: 50%; }
  .large-5 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-4 {
    margin-left: 66.66667%; }
  .large-6 {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-5 {
    margin-left: 83.33333%; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns, body.no-flexbox .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns, body.no-flexbox .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns, body.no-flexbox .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns, body.no-flexbox .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns, body.no-flexbox .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns, body.no-flexbox .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns, body.no-flexbox .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns, body.no-flexbox .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 48.0625em) and (min-width: 48.0625em) {
  .large-expand {
    flex: 1 1 0px; } }

.row.large-unstack > .column, .row.large-unstack > .columns, body.no-flexbox .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 48.0625em) {
    .row.large-unstack > .column, .row.large-unstack > .columns, body.no-flexbox .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 48.0625em) {
  .large-collapse > .column, .large-collapse > .columns, body.no-flexbox .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns, body.no-flexbox .large-uncollapse > .columns {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem; } }

@media screen and (min-width: 65.0625em) {
  .xlarge-1 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xlarge-offset-1 {
    margin-left: 16.66667%; }
  .xlarge-3 {
    flex: 0 0 50%;
    max-width: 50%; }
  .xlarge-offset-2 {
    margin-left: 33.33333%; }
  .xlarge-4 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xlarge-offset-3 {
    margin-left: 50%; }
  .xlarge-5 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xlarge-offset-4 {
    margin-left: 66.66667%; }
  .xlarge-6 {
    flex: 0 0 100%;
    max-width: 100%; }
  .xlarge-offset-5 {
    margin-left: 83.33333%; }
  .xlarge-up-1 {
    flex-wrap: wrap; }
    .xlarge-up-1 > .column, .xlarge-up-1 > .columns, body.no-flexbox .xlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .xlarge-up-2 {
    flex-wrap: wrap; }
    .xlarge-up-2 > .column, .xlarge-up-2 > .columns, body.no-flexbox .xlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .xlarge-up-3 {
    flex-wrap: wrap; }
    .xlarge-up-3 > .column, .xlarge-up-3 > .columns, body.no-flexbox .xlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xlarge-up-4 {
    flex-wrap: wrap; }
    .xlarge-up-4 > .column, .xlarge-up-4 > .columns, body.no-flexbox .xlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .xlarge-up-5 {
    flex-wrap: wrap; }
    .xlarge-up-5 > .column, .xlarge-up-5 > .columns, body.no-flexbox .xlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .xlarge-up-6 {
    flex-wrap: wrap; }
    .xlarge-up-6 > .column, .xlarge-up-6 > .columns, body.no-flexbox .xlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xlarge-up-7 {
    flex-wrap: wrap; }
    .xlarge-up-7 > .column, .xlarge-up-7 > .columns, body.no-flexbox .xlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xlarge-up-8 {
    flex-wrap: wrap; }
    .xlarge-up-8 > .column, .xlarge-up-8 > .columns, body.no-flexbox .xlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 65.0625em) and (min-width: 65.0625em) {
  .xlarge-expand {
    flex: 1 1 0px; } }

.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns, body.no-flexbox .row.xlarge-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 65.0625em) {
    .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns, body.no-flexbox .row.xlarge-unstack > .columns {
      flex: 1 1 0px; } }

@media screen and (min-width: 65.0625em) {
  .xlarge-collapse > .column, .xlarge-collapse > .columns, body.no-flexbox .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns, body.no-flexbox .xlarge-uncollapse > .columns {
    padding-right: 1rem;
    padding-left: 1rem; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 3.75rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40.0625em) {
    .column-block {
      margin-bottom: 2.625rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }
  @media screen and (min-width: 65.0625em) {
    .column-block {
      margin-bottom: 2rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }
  @media screen and (min-width: 81.25em) {
    .column-block {
      margin-bottom: 2.25rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

body.no-flexbox .row {
  max-width: 81.25rem;
  margin-right: auto;
  margin-left: auto; }
  body.no-flexbox .row::before, body.no-flexbox .row::after {
    display: table;
    content: ' '; }
  body.no-flexbox .row::after {
    clear: both; }
  body.no-flexbox .row.collapse > .column, body.no-flexbox .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  body.no-flexbox .row .row {
    margin-right: -1.875rem;
    margin-left: -1.875rem; }
    @media print, screen and (min-width: 40.0625em) {
      body.no-flexbox .row .row {
        margin-right: -1.3125rem;
        margin-left: -1.3125rem; } }
    @media print, screen and (min-width: 48.0625em) {
      body.no-flexbox .row .row {
        margin-right: -1.3125rem;
        margin-left: -1.3125rem; } }
    @media screen and (min-width: 65.0625em) {
      body.no-flexbox .row .row {
        margin-right: -1rem;
        margin-left: -1rem; } }
    body.no-flexbox .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  body.no-flexbox .row.expanded {
    max-width: none; }
    body.no-flexbox .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  body.no-flexbox .row:not(.expanded) .row {
    max-width: none; }
  body.no-flexbox .row.gutter-small > .column, body.no-flexbox .row.gutter-small > .columns {
    padding-right: 1.875rem;
    padding-left: 1.875rem; }
  body.no-flexbox .row.gutter-medium > .column, body.no-flexbox .row.gutter-medium > .columns {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem; }
  body.no-flexbox .row.gutter-xlarge > .column, body.no-flexbox .row.gutter-xlarge > .columns {
    padding-right: 1rem;
    padding-left: 1rem; }
  body.no-flexbox .row.gutter-xxlarge > .column, body.no-flexbox .row.gutter-xxlarge > .columns {
    padding-right: 1.125rem;
    padding-left: 1.125rem; }

body.no-flexbox .column, body.no-flexbox .columns {
  width: 100%;
  float: left;
  padding-right: 1.875rem;
  padding-left: 1.875rem; }
  @media print, screen and (min-width: 40.0625em) {
    body.no-flexbox .column, body.no-flexbox .columns {
      padding-right: 1.3125rem;
      padding-left: 1.3125rem; } }
  @media screen and (min-width: 65.0625em) {
    body.no-flexbox .column, body.no-flexbox .columns {
      padding-right: 1rem;
      padding-left: 1rem; } }
  @media screen and (min-width: 81.25em) {
    body.no-flexbox .column, body.no-flexbox .columns {
      padding-right: 1.125rem;
      padding-left: 1.125rem; } }
  body.no-flexbox .column:last-child:not(:first-child), body.no-flexbox .columns:last-child:not(:first-child) {
    float: right; }
  body.no-flexbox .column.end:last-child:last-child, body.no-flexbox .end.columns:last-child:last-child {
    float: left; }

body.no-flexbox .column.row.row, body.no-flexbox .row.row.columns {
  float: none; }

body.no-flexbox .row .column.row.row, body.no-flexbox .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

body.no-flexbox .small-1 {
  width: 16.66667%; }

body.no-flexbox .small-push-1 {
  position: relative;
  left: 16.66667%; }

body.no-flexbox .small-pull-1 {
  position: relative;
  left: -16.66667%; }

body.no-flexbox .small-offset-0 {
  margin-left: 0%; }

body.no-flexbox .small-2 {
  width: 33.33333%; }

body.no-flexbox .small-push-2 {
  position: relative;
  left: 33.33333%; }

body.no-flexbox .small-pull-2 {
  position: relative;
  left: -33.33333%; }

body.no-flexbox .small-offset-1 {
  margin-left: 16.66667%; }

body.no-flexbox .small-3 {
  width: 50%; }

body.no-flexbox .small-push-3 {
  position: relative;
  left: 50%; }

body.no-flexbox .small-pull-3 {
  position: relative;
  left: -50%; }

body.no-flexbox .small-offset-2 {
  margin-left: 33.33333%; }

body.no-flexbox .small-4 {
  width: 66.66667%; }

body.no-flexbox .small-push-4 {
  position: relative;
  left: 66.66667%; }

body.no-flexbox .small-pull-4 {
  position: relative;
  left: -66.66667%; }

body.no-flexbox .small-offset-3 {
  margin-left: 50%; }

body.no-flexbox .small-5 {
  width: 83.33333%; }

body.no-flexbox .small-push-5 {
  position: relative;
  left: 83.33333%; }

body.no-flexbox .small-pull-5 {
  position: relative;
  left: -83.33333%; }

body.no-flexbox .small-offset-4 {
  margin-left: 66.66667%; }

body.no-flexbox .small-6 {
  width: 100%; }

body.no-flexbox .small-offset-5 {
  margin-left: 83.33333%; }

body.no-flexbox .small-up-1 > .column, body.no-flexbox .small-up-1 > .columns {
  float: left;
  width: 100%; }
  body.no-flexbox .small-up-1 > .column:nth-of-type(1n), body.no-flexbox .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  body.no-flexbox .small-up-1 > .column:nth-of-type(1n+1), body.no-flexbox .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  body.no-flexbox .small-up-1 > .column:last-child, body.no-flexbox .small-up-1 > .columns:last-child {
    float: left; }

body.no-flexbox .small-up-2 > .column, body.no-flexbox .small-up-2 > .columns {
  float: left;
  width: 50%; }
  body.no-flexbox .small-up-2 > .column:nth-of-type(1n), body.no-flexbox .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  body.no-flexbox .small-up-2 > .column:nth-of-type(2n+1), body.no-flexbox .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  body.no-flexbox .small-up-2 > .column:last-child, body.no-flexbox .small-up-2 > .columns:last-child {
    float: left; }

body.no-flexbox .small-up-3 > .column, body.no-flexbox .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  body.no-flexbox .small-up-3 > .column:nth-of-type(1n), body.no-flexbox .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  body.no-flexbox .small-up-3 > .column:nth-of-type(3n+1), body.no-flexbox .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  body.no-flexbox .small-up-3 > .column:last-child, body.no-flexbox .small-up-3 > .columns:last-child {
    float: left; }

body.no-flexbox .small-up-4 > .column, body.no-flexbox .small-up-4 > .columns {
  float: left;
  width: 25%; }
  body.no-flexbox .small-up-4 > .column:nth-of-type(1n), body.no-flexbox .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  body.no-flexbox .small-up-4 > .column:nth-of-type(4n+1), body.no-flexbox .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  body.no-flexbox .small-up-4 > .column:last-child, body.no-flexbox .small-up-4 > .columns:last-child {
    float: left; }

body.no-flexbox .small-up-5 > .column, body.no-flexbox .small-up-5 > .columns {
  float: left;
  width: 20%; }
  body.no-flexbox .small-up-5 > .column:nth-of-type(1n), body.no-flexbox .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  body.no-flexbox .small-up-5 > .column:nth-of-type(5n+1), body.no-flexbox .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  body.no-flexbox .small-up-5 > .column:last-child, body.no-flexbox .small-up-5 > .columns:last-child {
    float: left; }

body.no-flexbox .small-up-6 > .column, body.no-flexbox .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  body.no-flexbox .small-up-6 > .column:nth-of-type(1n), body.no-flexbox .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  body.no-flexbox .small-up-6 > .column:nth-of-type(6n+1), body.no-flexbox .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  body.no-flexbox .small-up-6 > .column:last-child, body.no-flexbox .small-up-6 > .columns:last-child {
    float: left; }

body.no-flexbox .small-up-7 > .column, body.no-flexbox .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  body.no-flexbox .small-up-7 > .column:nth-of-type(1n), body.no-flexbox .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  body.no-flexbox .small-up-7 > .column:nth-of-type(7n+1), body.no-flexbox .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  body.no-flexbox .small-up-7 > .column:last-child, body.no-flexbox .small-up-7 > .columns:last-child {
    float: left; }

body.no-flexbox .small-up-8 > .column, body.no-flexbox .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  body.no-flexbox .small-up-8 > .column:nth-of-type(1n), body.no-flexbox .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  body.no-flexbox .small-up-8 > .column:nth-of-type(8n+1), body.no-flexbox .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  body.no-flexbox .small-up-8 > .column:last-child, body.no-flexbox .small-up-8 > .columns:last-child {
    float: left; }

body.no-flexbox .small-collapse > .column, body.no-flexbox .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

body.no-flexbox .small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

body.no-flexbox .expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

body.no-flexbox .small-uncollapse > .column, body.no-flexbox .small-uncollapse > .columns {
  padding-right: 1.875rem;
  padding-left: 1.875rem; }

body.no-flexbox .small-centered {
  margin-right: auto;
  margin-left: auto; }
  body.no-flexbox .small-centered, body.no-flexbox .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

body.no-flexbox .small-uncentered,
body.no-flexbox .small-push-0,
body.no-flexbox .small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40.0625em) {
  body.no-flexbox .medium-1 {
    width: 16.66667%; }
  body.no-flexbox .medium-push-1 {
    position: relative;
    left: 16.66667%; }
  body.no-flexbox .medium-pull-1 {
    position: relative;
    left: -16.66667%; }
  body.no-flexbox .medium-offset-0 {
    margin-left: 0%; }
  body.no-flexbox .medium-2 {
    width: 33.33333%; }
  body.no-flexbox .medium-push-2 {
    position: relative;
    left: 33.33333%; }
  body.no-flexbox .medium-pull-2 {
    position: relative;
    left: -33.33333%; }
  body.no-flexbox .medium-offset-1 {
    margin-left: 16.66667%; }
  body.no-flexbox .medium-3 {
    width: 50%; }
  body.no-flexbox .medium-push-3 {
    position: relative;
    left: 50%; }
  body.no-flexbox .medium-pull-3 {
    position: relative;
    left: -50%; }
  body.no-flexbox .medium-offset-2 {
    margin-left: 33.33333%; }
  body.no-flexbox .medium-4 {
    width: 66.66667%; }
  body.no-flexbox .medium-push-4 {
    position: relative;
    left: 66.66667%; }
  body.no-flexbox .medium-pull-4 {
    position: relative;
    left: -66.66667%; }
  body.no-flexbox .medium-offset-3 {
    margin-left: 50%; }
  body.no-flexbox .medium-5 {
    width: 83.33333%; }
  body.no-flexbox .medium-push-5 {
    position: relative;
    left: 83.33333%; }
  body.no-flexbox .medium-pull-5 {
    position: relative;
    left: -83.33333%; }
  body.no-flexbox .medium-offset-4 {
    margin-left: 66.66667%; }
  body.no-flexbox .medium-6 {
    width: 100%; }
  body.no-flexbox .medium-offset-5 {
    margin-left: 83.33333%; }
  body.no-flexbox .medium-up-1 > .column, body.no-flexbox .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    body.no-flexbox .medium-up-1 > .column:nth-of-type(1n), body.no-flexbox .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .medium-up-1 > .column:nth-of-type(1n+1), body.no-flexbox .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    body.no-flexbox .medium-up-1 > .column:last-child, body.no-flexbox .medium-up-1 > .columns:last-child {
      float: left; }
  body.no-flexbox .medium-up-2 > .column, body.no-flexbox .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    body.no-flexbox .medium-up-2 > .column:nth-of-type(1n), body.no-flexbox .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .medium-up-2 > .column:nth-of-type(2n+1), body.no-flexbox .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    body.no-flexbox .medium-up-2 > .column:last-child, body.no-flexbox .medium-up-2 > .columns:last-child {
      float: left; }
  body.no-flexbox .medium-up-3 > .column, body.no-flexbox .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    body.no-flexbox .medium-up-3 > .column:nth-of-type(1n), body.no-flexbox .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .medium-up-3 > .column:nth-of-type(3n+1), body.no-flexbox .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    body.no-flexbox .medium-up-3 > .column:last-child, body.no-flexbox .medium-up-3 > .columns:last-child {
      float: left; }
  body.no-flexbox .medium-up-4 > .column, body.no-flexbox .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    body.no-flexbox .medium-up-4 > .column:nth-of-type(1n), body.no-flexbox .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .medium-up-4 > .column:nth-of-type(4n+1), body.no-flexbox .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    body.no-flexbox .medium-up-4 > .column:last-child, body.no-flexbox .medium-up-4 > .columns:last-child {
      float: left; }
  body.no-flexbox .medium-up-5 > .column, body.no-flexbox .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    body.no-flexbox .medium-up-5 > .column:nth-of-type(1n), body.no-flexbox .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .medium-up-5 > .column:nth-of-type(5n+1), body.no-flexbox .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    body.no-flexbox .medium-up-5 > .column:last-child, body.no-flexbox .medium-up-5 > .columns:last-child {
      float: left; }
  body.no-flexbox .medium-up-6 > .column, body.no-flexbox .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    body.no-flexbox .medium-up-6 > .column:nth-of-type(1n), body.no-flexbox .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .medium-up-6 > .column:nth-of-type(6n+1), body.no-flexbox .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    body.no-flexbox .medium-up-6 > .column:last-child, body.no-flexbox .medium-up-6 > .columns:last-child {
      float: left; }
  body.no-flexbox .medium-up-7 > .column, body.no-flexbox .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    body.no-flexbox .medium-up-7 > .column:nth-of-type(1n), body.no-flexbox .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .medium-up-7 > .column:nth-of-type(7n+1), body.no-flexbox .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    body.no-flexbox .medium-up-7 > .column:last-child, body.no-flexbox .medium-up-7 > .columns:last-child {
      float: left; }
  body.no-flexbox .medium-up-8 > .column, body.no-flexbox .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    body.no-flexbox .medium-up-8 > .column:nth-of-type(1n), body.no-flexbox .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .medium-up-8 > .column:nth-of-type(8n+1), body.no-flexbox .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    body.no-flexbox .medium-up-8 > .column:last-child, body.no-flexbox .medium-up-8 > .columns:last-child {
      float: left; }
  body.no-flexbox .medium-collapse > .column, body.no-flexbox .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  body.no-flexbox .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  body.no-flexbox .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  body.no-flexbox .medium-uncollapse > .column, body.no-flexbox .medium-uncollapse > .columns {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem; }
  body.no-flexbox .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    body.no-flexbox .medium-centered, body.no-flexbox .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  body.no-flexbox .medium-uncentered,
  body.no-flexbox .medium-push-0,
  body.no-flexbox .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 48.0625em) {
  body.no-flexbox .large-1 {
    width: 16.66667%; }
  body.no-flexbox .large-push-1 {
    position: relative;
    left: 16.66667%; }
  body.no-flexbox .large-pull-1 {
    position: relative;
    left: -16.66667%; }
  body.no-flexbox .large-offset-0 {
    margin-left: 0%; }
  body.no-flexbox .large-2 {
    width: 33.33333%; }
  body.no-flexbox .large-push-2 {
    position: relative;
    left: 33.33333%; }
  body.no-flexbox .large-pull-2 {
    position: relative;
    left: -33.33333%; }
  body.no-flexbox .large-offset-1 {
    margin-left: 16.66667%; }
  body.no-flexbox .large-3 {
    width: 50%; }
  body.no-flexbox .large-push-3 {
    position: relative;
    left: 50%; }
  body.no-flexbox .large-pull-3 {
    position: relative;
    left: -50%; }
  body.no-flexbox .large-offset-2 {
    margin-left: 33.33333%; }
  body.no-flexbox .large-4 {
    width: 66.66667%; }
  body.no-flexbox .large-push-4 {
    position: relative;
    left: 66.66667%; }
  body.no-flexbox .large-pull-4 {
    position: relative;
    left: -66.66667%; }
  body.no-flexbox .large-offset-3 {
    margin-left: 50%; }
  body.no-flexbox .large-5 {
    width: 83.33333%; }
  body.no-flexbox .large-push-5 {
    position: relative;
    left: 83.33333%; }
  body.no-flexbox .large-pull-5 {
    position: relative;
    left: -83.33333%; }
  body.no-flexbox .large-offset-4 {
    margin-left: 66.66667%; }
  body.no-flexbox .large-6 {
    width: 100%; }
  body.no-flexbox .large-offset-5 {
    margin-left: 83.33333%; }
  body.no-flexbox .large-up-1 > .column, body.no-flexbox .large-up-1 > .columns {
    float: left;
    width: 100%; }
    body.no-flexbox .large-up-1 > .column:nth-of-type(1n), body.no-flexbox .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .large-up-1 > .column:nth-of-type(1n+1), body.no-flexbox .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    body.no-flexbox .large-up-1 > .column:last-child, body.no-flexbox .large-up-1 > .columns:last-child {
      float: left; }
  body.no-flexbox .large-up-2 > .column, body.no-flexbox .large-up-2 > .columns {
    float: left;
    width: 50%; }
    body.no-flexbox .large-up-2 > .column:nth-of-type(1n), body.no-flexbox .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .large-up-2 > .column:nth-of-type(2n+1), body.no-flexbox .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    body.no-flexbox .large-up-2 > .column:last-child, body.no-flexbox .large-up-2 > .columns:last-child {
      float: left; }
  body.no-flexbox .large-up-3 > .column, body.no-flexbox .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    body.no-flexbox .large-up-3 > .column:nth-of-type(1n), body.no-flexbox .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .large-up-3 > .column:nth-of-type(3n+1), body.no-flexbox .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    body.no-flexbox .large-up-3 > .column:last-child, body.no-flexbox .large-up-3 > .columns:last-child {
      float: left; }
  body.no-flexbox .large-up-4 > .column, body.no-flexbox .large-up-4 > .columns {
    float: left;
    width: 25%; }
    body.no-flexbox .large-up-4 > .column:nth-of-type(1n), body.no-flexbox .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .large-up-4 > .column:nth-of-type(4n+1), body.no-flexbox .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    body.no-flexbox .large-up-4 > .column:last-child, body.no-flexbox .large-up-4 > .columns:last-child {
      float: left; }
  body.no-flexbox .large-up-5 > .column, body.no-flexbox .large-up-5 > .columns {
    float: left;
    width: 20%; }
    body.no-flexbox .large-up-5 > .column:nth-of-type(1n), body.no-flexbox .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .large-up-5 > .column:nth-of-type(5n+1), body.no-flexbox .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    body.no-flexbox .large-up-5 > .column:last-child, body.no-flexbox .large-up-5 > .columns:last-child {
      float: left; }
  body.no-flexbox .large-up-6 > .column, body.no-flexbox .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    body.no-flexbox .large-up-6 > .column:nth-of-type(1n), body.no-flexbox .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .large-up-6 > .column:nth-of-type(6n+1), body.no-flexbox .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    body.no-flexbox .large-up-6 > .column:last-child, body.no-flexbox .large-up-6 > .columns:last-child {
      float: left; }
  body.no-flexbox .large-up-7 > .column, body.no-flexbox .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    body.no-flexbox .large-up-7 > .column:nth-of-type(1n), body.no-flexbox .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .large-up-7 > .column:nth-of-type(7n+1), body.no-flexbox .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    body.no-flexbox .large-up-7 > .column:last-child, body.no-flexbox .large-up-7 > .columns:last-child {
      float: left; }
  body.no-flexbox .large-up-8 > .column, body.no-flexbox .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    body.no-flexbox .large-up-8 > .column:nth-of-type(1n), body.no-flexbox .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .large-up-8 > .column:nth-of-type(8n+1), body.no-flexbox .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    body.no-flexbox .large-up-8 > .column:last-child, body.no-flexbox .large-up-8 > .columns:last-child {
      float: left; }
  body.no-flexbox .large-collapse > .column, body.no-flexbox .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  body.no-flexbox .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  body.no-flexbox .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  body.no-flexbox .large-uncollapse > .column, body.no-flexbox .large-uncollapse > .columns {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem; }
  body.no-flexbox .large-centered {
    margin-right: auto;
    margin-left: auto; }
    body.no-flexbox .large-centered, body.no-flexbox .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  body.no-flexbox .large-uncentered,
  body.no-flexbox .large-push-0,
  body.no-flexbox .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 65.0625em) {
  body.no-flexbox .xlarge-1 {
    width: 16.66667%; }
  body.no-flexbox .xlarge-push-1 {
    position: relative;
    left: 16.66667%; }
  body.no-flexbox .xlarge-pull-1 {
    position: relative;
    left: -16.66667%; }
  body.no-flexbox .xlarge-offset-0 {
    margin-left: 0%; }
  body.no-flexbox .xlarge-2 {
    width: 33.33333%; }
  body.no-flexbox .xlarge-push-2 {
    position: relative;
    left: 33.33333%; }
  body.no-flexbox .xlarge-pull-2 {
    position: relative;
    left: -33.33333%; }
  body.no-flexbox .xlarge-offset-1 {
    margin-left: 16.66667%; }
  body.no-flexbox .xlarge-3 {
    width: 50%; }
  body.no-flexbox .xlarge-push-3 {
    position: relative;
    left: 50%; }
  body.no-flexbox .xlarge-pull-3 {
    position: relative;
    left: -50%; }
  body.no-flexbox .xlarge-offset-2 {
    margin-left: 33.33333%; }
  body.no-flexbox .xlarge-4 {
    width: 66.66667%; }
  body.no-flexbox .xlarge-push-4 {
    position: relative;
    left: 66.66667%; }
  body.no-flexbox .xlarge-pull-4 {
    position: relative;
    left: -66.66667%; }
  body.no-flexbox .xlarge-offset-3 {
    margin-left: 50%; }
  body.no-flexbox .xlarge-5 {
    width: 83.33333%; }
  body.no-flexbox .xlarge-push-5 {
    position: relative;
    left: 83.33333%; }
  body.no-flexbox .xlarge-pull-5 {
    position: relative;
    left: -83.33333%; }
  body.no-flexbox .xlarge-offset-4 {
    margin-left: 66.66667%; }
  body.no-flexbox .xlarge-6 {
    width: 100%; }
  body.no-flexbox .xlarge-offset-5 {
    margin-left: 83.33333%; }
  body.no-flexbox .xlarge-up-1 > .column, body.no-flexbox .xlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    body.no-flexbox .xlarge-up-1 > .column:nth-of-type(1n), body.no-flexbox .xlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .xlarge-up-1 > .column:nth-of-type(1n+1), body.no-flexbox .xlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    body.no-flexbox .xlarge-up-1 > .column:last-child, body.no-flexbox .xlarge-up-1 > .columns:last-child {
      float: left; }
  body.no-flexbox .xlarge-up-2 > .column, body.no-flexbox .xlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    body.no-flexbox .xlarge-up-2 > .column:nth-of-type(1n), body.no-flexbox .xlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .xlarge-up-2 > .column:nth-of-type(2n+1), body.no-flexbox .xlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    body.no-flexbox .xlarge-up-2 > .column:last-child, body.no-flexbox .xlarge-up-2 > .columns:last-child {
      float: left; }
  body.no-flexbox .xlarge-up-3 > .column, body.no-flexbox .xlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    body.no-flexbox .xlarge-up-3 > .column:nth-of-type(1n), body.no-flexbox .xlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .xlarge-up-3 > .column:nth-of-type(3n+1), body.no-flexbox .xlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    body.no-flexbox .xlarge-up-3 > .column:last-child, body.no-flexbox .xlarge-up-3 > .columns:last-child {
      float: left; }
  body.no-flexbox .xlarge-up-4 > .column, body.no-flexbox .xlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    body.no-flexbox .xlarge-up-4 > .column:nth-of-type(1n), body.no-flexbox .xlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .xlarge-up-4 > .column:nth-of-type(4n+1), body.no-flexbox .xlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    body.no-flexbox .xlarge-up-4 > .column:last-child, body.no-flexbox .xlarge-up-4 > .columns:last-child {
      float: left; }
  body.no-flexbox .xlarge-up-5 > .column, body.no-flexbox .xlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    body.no-flexbox .xlarge-up-5 > .column:nth-of-type(1n), body.no-flexbox .xlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .xlarge-up-5 > .column:nth-of-type(5n+1), body.no-flexbox .xlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    body.no-flexbox .xlarge-up-5 > .column:last-child, body.no-flexbox .xlarge-up-5 > .columns:last-child {
      float: left; }
  body.no-flexbox .xlarge-up-6 > .column, body.no-flexbox .xlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    body.no-flexbox .xlarge-up-6 > .column:nth-of-type(1n), body.no-flexbox .xlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .xlarge-up-6 > .column:nth-of-type(6n+1), body.no-flexbox .xlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    body.no-flexbox .xlarge-up-6 > .column:last-child, body.no-flexbox .xlarge-up-6 > .columns:last-child {
      float: left; }
  body.no-flexbox .xlarge-up-7 > .column, body.no-flexbox .xlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    body.no-flexbox .xlarge-up-7 > .column:nth-of-type(1n), body.no-flexbox .xlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .xlarge-up-7 > .column:nth-of-type(7n+1), body.no-flexbox .xlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    body.no-flexbox .xlarge-up-7 > .column:last-child, body.no-flexbox .xlarge-up-7 > .columns:last-child {
      float: left; }
  body.no-flexbox .xlarge-up-8 > .column, body.no-flexbox .xlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    body.no-flexbox .xlarge-up-8 > .column:nth-of-type(1n), body.no-flexbox .xlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    body.no-flexbox .xlarge-up-8 > .column:nth-of-type(8n+1), body.no-flexbox .xlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    body.no-flexbox .xlarge-up-8 > .column:last-child, body.no-flexbox .xlarge-up-8 > .columns:last-child {
      float: left; }
  body.no-flexbox .xlarge-collapse > .column, body.no-flexbox .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  body.no-flexbox .xlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  body.no-flexbox .expanded.row .xlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  body.no-flexbox .xlarge-uncollapse > .column, body.no-flexbox .xlarge-uncollapse > .columns {
    padding-right: 1rem;
    padding-left: 1rem; }
  body.no-flexbox .xlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    body.no-flexbox .xlarge-centered, body.no-flexbox .xlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  body.no-flexbox .xlarge-uncentered,
  body.no-flexbox .xlarge-push-0,
  body.no-flexbox .xlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

body.no-flexbox .column-block {
  margin-bottom: 3.75rem; }
  body.no-flexbox .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40.0625em) {
    body.no-flexbox .column-block {
      margin-bottom: 2.625rem; }
      body.no-flexbox .column-block > :last-child {
        margin-bottom: 0; } }
  @media screen and (min-width: 65.0625em) {
    body.no-flexbox .column-block {
      margin-bottom: 2rem; }
      body.no-flexbox .column-block > :last-child {
        margin-bottom: 0; } }
  @media screen and (min-width: 81.25em) {
    body.no-flexbox .column-block {
      margin-bottom: 2.25rem; }
      body.no-flexbox .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.4;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "gtpressura", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #333333;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40.0625em) {
  h1, .h1 {
    font-size: 9.1875rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #000;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #595959; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 81.25rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.4; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.4;
    color: #333333; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #333333; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #000; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #333333; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40.0625em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 48.0625em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 65.0625em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #333333;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: 0; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: #fff;
  cursor: pointer; }

.flickity-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f; }

.flickity-button:active {
  opacity: .6; }

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none; }

.flickity-button-icon {
  fill: #333; }

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: .25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

@font-face {
  font-family: 'gtpressura';
  src: url("../fonts/GT-Pressura-Bold.woff2") format("woff2"), url("../fonts/GT-Pressura-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'TTNorms';
  src: url("../fonts/TTNorms-Regular.woff2") format("woff2"), url("../fonts/TTNorms-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'TTNorms';
  src: url("../fonts/TTNorms-Bold.woff2") format("woff2"), url("../fonts/TTNorms-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

html,
body {
  overflow-x: hidden;
  background: #000; }

html.ovh,
body.ovh {
  overflow-y: hidden; }

::scrollbar {
  display: none; }

.site--wrapper {
  position: relative; }

ul {
  margin: 0; }

img {
  width: auto; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/***SLICK THEME****/
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  display: block;
  width: 20px;
  height: 30px;
  margin-top: -10px \9;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.no-touch .slick-prev:hover,
.slick-prev:focus,
.no-touch .slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.no-touch .slick-prev:hover:before,
.slick-prev:focus:before,
.no-touch .slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .5; }

.slick-prev:before,
.slick-next:before {
  font-family: FontAwesome;
  font-size: 30px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 0; }

[dir='rtl'] .slick-prev {
  right: 0;
  left: auto; }

.slick-prev:before {
  content: "\f053"; }

[dir='rtl'] .slick-prev:before {
  content: "\f054"; }

.slick-next {
  right: 0; }

[dir='rtl'] .slick-next {
  right: auto;
  left: 0; }

.slick-next:before {
  content: "\f054"; }

[dir='rtl'] .slick-next:before {
  content: "\f053"; }

/* Dots */
.slick-dots {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  margin-left: 0; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: auto;
  height: auto;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.no-touch .slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:before {
  content: '';
  font-size: 0;
  line-height: 20px;
  position: static;
  display: block;
  width: 15px;
  height: 15px;
  background-color: #000000;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease; }

.no-touch .slick-dots li button:hover:before,
.slick-dots li button:focus:before,
.slick-dots li.slick-active button:before {
  opacity: 1;
  background-color: #fff; }

.header {
  padding: 20px 20px 5px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  transition: 0.3s all;
  z-index: 99;
  transform: translateY(0); }
  .header.hdn {
    opacity: 0;
    visibility: hidden; }
  @media (max-width: 767px) {
    .header {
      padding: 15px 15px 5px; } }
  .header-sticky {
    position: fixed !important;
    width: 100%;
    opacity: 0;
    transform: translateY(-100%); }
  .header-show {
    transform: translateY(0);
    opacity: 1;
    background: red; }
  .header.nav-down {
    background: #000; }
  .header.nav-up {
    transform: translateY(-100%); }
  .header.header_top {
    background: transparent; }

.hamburger_icon {
  max-width: 112px;
  width: 100%;
  height: 67px;
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  transform: scale(1.1); }
  @media (max-width: 640px) {
    .hamburger_icon {
      height: 25px;
      transform: scale(1.5); } }
  .hamburger_icon .icon {
    position: absolute;
    content: "";
    width: 21px;
    height: 11px;
    margin: 0 auto;
    top: 38%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    cursor: pointer;
    z-index: 2; }
    .hamburger_icon .icon:before {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
      height: 2px;
      width: 100%;
      box-shadow: 0 2px 0 0 #fff, 0 10px 0 0 #FFF; }
  .hamburger_icon img {
    display: none;
    width: 112px;
    height: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }

.site--navigation {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  color: #FFF;
  background: #000;
  z-index: 100;
  min-height: 100vh; }
  .site--navigation.opnd {
    opacity: 1;
    visibility: visible; }
  .site--navigation .close_nav {
    position: absolute;
    right: 29px;
    top: 28px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 9; }
    @media (max-width: 640px) {
      .site--navigation .close_nav {
        top: 10px;
        right: 10px; } }
    .site--navigation .close_nav:before, .site--navigation .close_nav:after {
      position: absolute;
      content: "";
      height: 2px;
      background: #FFF;
      width: 70%;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: translateY(-50%) rotate(45deg);
      transition: all .3s; }
    .site--navigation .close_nav:after {
      transform: translateY(-50%) rotate(-45deg); }
  .site--navigation.fade-in .nav ul li {
    opacity: 1;
    transform: translate3d(0, -50px, 0); }
  .site--navigation .nav {
    flex-basis: 100%;
    text-align: left;
    padding: 0 25px;
    width: calc(100% + 20px); }
    @media (max-width: 640px) {
      .site--navigation .nav {
        padding: 0 65px 0 15px; } }
    .site--navigation .nav.js-loop {
      position: relative;
      height: 100%;
      overflow: auto; }
    .site--navigation .nav ul {
      margin: 0; }
      .site--navigation .nav ul li {
        list-style: none;
        line-height: 0.95;
        display: flex;
        align-items: center;
        position: relative;
        opacity: 0;
        transition: scale .4s, opacity .25s ease-out, transform .75s ease-out; }
        .site--navigation .nav ul li.scale {
          transform: scale(0.8); }
        .site--navigation .nav ul li a {
          font-family: "gtpressura", Helvetica, Roboto, Arial, sans-serif;
          font-weight: bold;
          color: #FFF;
          text-transform: uppercase;
          font-size: 162px;
          transition: all .3s; }
          @media (max-width: 1200px) {
            .site--navigation .nav ul li a {
              font-size: 100px; } }
          @media (max-width: 640px) {
            .site--navigation .nav ul li a {
              font-size: 50px; } }

.no-touch .site--navigation .close_nav:hover:before, .no-touch .site--navigation .close_nav:hover:after {
  box-shadow: -2px 0 0 0 #fff, 2px 0 0 0 #fff; }

.no-touch .site--navigation .nav ul li a:hover {
  -webkit-text-fill-color: black;
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: white;
  color: #000; }
  @media (max-width: 640px) {
    .no-touch .site--navigation .nav ul li a:hover {
      -webkit-text-stroke-width: 2px; } }

.ie_browser .site--navigation .nav ul li a:hover {
  color: #FFF; }

.test_btn {
  position: fixed;
  top: 0;
  left: 0;
  display: inline-block;
  padding: 10px;
  font-size: 22px;
  background: red;
  color: #000;
  max-width: 250px;
  width: 100%;
  z-index: 99999999999;
  text-align: center; }
  .test_btn.active {
    background: blue; }

@keyframes music_1 {
  from {
    height: 30%; }
  50% {
    height: 70%; }
  to {
    height: 100%; } }

@keyframes music_2 {
  from {
    height: 65%; }
  50% {
    height: 30%; }
  to {
    height: 90%; } }

@keyframes music_3 {
  from {
    height: 80%; }
  50% {
    height: 25%; }
  to {
    height: 100%; } }

@keyframes music_4 {
  from {
    height: 50%; }
  50% {
    height: 30%; }
  to {
    height: 95%; } }

@keyframes music_5 {
  from {
    height: 60%; }
  50% {
    height: 30%; }
  to {
    height: 100%; } }

@keyframes downMove {
  from {
    transform: translateY(-100%); }
  to {
    transform: translateY(100%); } }

.music_icon {
  width: 22px;
  height: 14px;
  font-size: 0;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: .6;
  transition: opacity .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  transform: translateY(-50%) scale(1.1); }
  @media (max-width: 640px) {
    .music_icon {
      display: none; } }
  .music_icon span {
    width: 2px;
    background: #FFF;
    height: 100%;
    display: inline-block; }
    .music_icon span + span {
      margin-left: 3px; }
  .music_icon.play span {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate; }
    .music_icon.play span:nth-child(1) {
      height: 30%;
      animation-name: music_1;
      animation-duration: 0.7s; }
    .music_icon.play span:nth-child(2) {
      height: 65%;
      animation-name: music_2;
      animation-duration: 0.9s; }
    .music_icon.play span:nth-child(3) {
      height: 80%;
      animation-name: music_3; }
    .music_icon.play span:nth-child(4) {
      height: 50%;
      animation-name: music_4;
      animation-duration: 1.1s; }
    .music_icon.play span:nth-child(5) {
      height: 60%;
      animation-name: music_5; }
  .music_icon.pause span {
    animation-play-state: paused;
    height: 60%; }

.no-touch .music_icon:hover {
  opacity: 1; }

@media (min-width: 768px) {
  .no-touch .branding_animation_widget .music_icon,
  .no-touch .vimeo_animation_widget .music_icon {
    opacity: 0; } }

.main_banner {
  height: calc(100vh + 1px);
  background: #000;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 80px 30px; }
  @media (max-width: 640px) {
    .main_banner {
      padding: 60px 0px; } }
  .main_banner audio {
    display: none; }
  .main_banner .down_icon {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    width: 17px;
    text-align: center;
    margin: 0 auto;
    z-index: 3;
    height: 24px;
    transform-origin: center;
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: scale(1.1); }
    @media (max-width: 640px) {
      .main_banner .down_icon {
        transform: scale(1.5); } }
    .main_banner .down_icon .line {
      width: 1px;
      height: 100%;
      margin: 0 auto;
      display: inline-block;
      overflow: hidden;
      position: relative; }
      .main_banner .down_icon .line:before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #FFF;
        animation: downMove 3.6s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; }
    .main_banner .down_icon .arrow {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 11px;
      height: 11px;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: rotate(-45deg);
      margin: 0 auto; }
  .main_banner__slider {
    flex-basis: 100%; }
    .main_banner__slider .preload_img {
      max-width: 1260px;
      margin: 0 auto; }
      .main_banner__slider .preload_img img {
        max-width: 100%;
        width: auto; }
        @media (max-width: 640px) {
          .main_banner__slider .preload_img img {
            max-height: calc(100vh - 100px); } }
  .main_banner--slide {
    position: relative;
    text-align: center;
    display: none; }
    .main_banner--slide.first_slide {
      display: block !important; }
    .main_banner--slide.removed {
      display: none !important; }
    .main_banner--slide .inner_block {
      max-width: 1260px;
      margin: 0 auto; }
    .main_banner--slide .slide_caption {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      background: #FFF;
      line-height: 1;
      padding: 24px 5px;
      color: #000;
      font-size: 73px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
      max-width: 660px;
      width: calc(100% - 30px);
      text-transform: uppercase; }
      @media (max-width: 767px) {
        .main_banner--slide .slide_caption {
          font-size: 48px;
          padding: 18px 5px; } }
      @media (max-width: 640px) {
        .main_banner--slide .slide_caption {
          font-size: 36px;
          padding: 12px 5px; } }
      .main_banner--slide .slide_caption .heart_icon {
        display: inline-block; }
        .main_banner--slide .slide_caption .heart_icon img {
          width: 60px;
          height: 60px;
          transform: translateY(-7px); }
          @media (max-width: 767px) {
            .main_banner--slide .slide_caption .heart_icon img {
              width: 40px;
              height: 40px;
              transform: translateY(-4px); } }
          @media (max-width: 640px) {
            .main_banner--slide .slide_caption .heart_icon img {
              width: 30px;
              height: 30px; } }
    .main_banner--slide img {
      max-width: 100%;
      max-height: 100vh; }
      @media (max-width: 640px) {
        .main_banner--slide img {
          max-height: calc(100vh - 100px);
          transform: scale(1.3); } }

.no-touch .main_banner .down_icon:hover {
  transform: rotate3d(0, 1, 0, 180deg); }

@keyframes slider_caption {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.widget_smpl_slider_widg, .no-touch .widget_smpl_slider_widg {
  position: relative;
  background: #000; }
  @media (max-width: 640px) {
    .widget_smpl_slider_widg > *:not(#space), .no-touch .widget_smpl_slider_widg > *:not(#space) {
      display: none !important; } }
  .widget_smpl_slider_widg .slick-prev, .widget_smpl_slider_widg .slick-next, .no-touch .widget_smpl_slider_widg .slick-prev, .no-touch .widget_smpl_slider_widg .slick-next {
    font-size: 20px;
    color: #FFF;
    bottom: 20px;
    top: auto;
    transform: translate(0, 0);
    width: auto;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold; }
    .widget_smpl_slider_widg .slick-prev:before, .widget_smpl_slider_widg .slick-next:before, .no-touch .widget_smpl_slider_widg .slick-prev:before, .no-touch .widget_smpl_slider_widg .slick-next:before {
      display: none; }
    .widget_smpl_slider_widg .slick-prev .line_block, .widget_smpl_slider_widg .slick-next .line_block, .no-touch .widget_smpl_slider_widg .slick-prev .line_block, .no-touch .widget_smpl_slider_widg .slick-next .line_block {
      width: 19px;
      height: 2px;
      overflow: hidden;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 50%; }
      .widget_smpl_slider_widg .slick-prev .line_block:before, .widget_smpl_slider_widg .slick-prev .line_block:after, .widget_smpl_slider_widg .slick-next .line_block:before, .widget_smpl_slider_widg .slick-next .line_block:after, .no-touch .widget_smpl_slider_widg .slick-prev .line_block:before, .no-touch .widget_smpl_slider_widg .slick-prev .line_block:after, .no-touch .widget_smpl_slider_widg .slick-next .line_block:before, .no-touch .widget_smpl_slider_widg .slick-next .line_block:after {
        position: absolute;
        content: "";
        width: 19px;
        height: 2px;
        background: #FFF;
        top: 0;
        left: 0; }
      .widget_smpl_slider_widg .slick-prev .line_block:before, .widget_smpl_slider_widg .slick-next .line_block:before, .no-touch .widget_smpl_slider_widg .slick-prev .line_block:before, .no-touch .widget_smpl_slider_widg .slick-next .line_block:before {
        animation: rightMove 3.6s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; }
      .widget_smpl_slider_widg .slick-prev .line_block:after, .widget_smpl_slider_widg .slick-next .line_block:after, .no-touch .widget_smpl_slider_widg .slick-prev .line_block:after, .no-touch .widget_smpl_slider_widg .slick-next .line_block:after {
        opacity: 0;
        transition: all .3s; }
  .widget_smpl_slider_widg .slick-prev, .no-touch .widget_smpl_slider_widg .slick-prev {
    left: 30px;
    padding-right: 27px; }
    .widget_smpl_slider_widg .slick-prev .line_block, .no-touch .widget_smpl_slider_widg .slick-prev .line_block {
      left: auto;
      right: 0; }
  .widget_smpl_slider_widg .slick-next, .no-touch .widget_smpl_slider_widg .slick-next {
    right: 30px;
    padding-left: 27px; }
  .widget_smpl_slider_widg .simple_slider_title, .no-touch .widget_smpl_slider_widg .simple_slider_title {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    background: #FFF;
    line-height: 1;
    padding: 24px 5px;
    color: #000;
    font-size: 73px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    max-width: 660px;
    width: calc(100% - 30px);
    text-transform: uppercase;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    transition: all .4s; }
    .widget_smpl_slider_widg .simple_slider_title.hdn.vsbl, .no-touch .widget_smpl_slider_widg .simple_slider_title.hdn.vsbl {
      visibility: visible;
      opacity: 1; }
    @media (max-width: 767px) {
      .widget_smpl_slider_widg .simple_slider_title, .no-touch .widget_smpl_slider_widg .simple_slider_title {
        font-size: 48px;
        padding: 18px 5px; } }
    @media (max-width: 767px) {
      .widget_smpl_slider_widg .simple_slider_title, .no-touch .widget_smpl_slider_widg .simple_slider_title {
        font-size: 36px;
        padding: 12px 5px; } }
  .widget_smpl_slider_widg .pagingInfo, .no-touch .widget_smpl_slider_widg .pagingInfo {
    position: absolute;
    bottom: 34px;
    right: 32px;
    width: 14px;
    text-align: right;
    display: none; }
    .widget_smpl_slider_widg .pagingInfo span, .no-touch .widget_smpl_slider_widg .pagingInfo span {
      color: #FFF;
      font-size: 12px;
      font-weight: bold;
      display: block;
      font-family: "gtpressura", Helvetica, Roboto, Arial, sans-serif; }
      .widget_smpl_slider_widg .pagingInfo span + span.all, .no-touch .widget_smpl_slider_widg .pagingInfo span + span.all {
        margin-top: 24px;
        position: relative; }
        .widget_smpl_slider_widg .pagingInfo span + span.all:before, .no-touch .widget_smpl_slider_widg .pagingInfo span + span.all:before {
          position: absolute;
          content: "-";
          top: -21px;
          left: 0;
          right: 0;
          text-align: center;
          color: #FFF;
          font-size: inherit;
          font-weight: bold;
          font-family: inherit; }

.no-touch .widget_smpl_slider_widg .slick-prev:hover, .no-touch .widget_smpl_slider_widg .slick-next:hover {
  font-size: 13px; }
  .no-touch .widget_smpl_slider_widg .slick-prev:hover .line_block:after, .no-touch .widget_smpl_slider_widg .slick-next:hover .line_block:after {
    opacity: 1; }

.home_slide {
  height: 100vh;
  background: #FFF;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat; }
  @media (max-width: 640px) {
    .home_slide {
      height: auto; } }
  .home_slide img {
    object-fit: cover;
    width: 100%;
    height: 100%; }
  .home_slide--caption {
    position: absolute;
    left: 36px;
    bottom: 33px;
    opacity: 0; }
    .home_slide--caption span {
      font-size: 10.4px;
      text-transform: uppercase;
      color: #FFF;
      font-weight: bold;
      font-family: "TTNorms", Helvetica, Roboto, Arial, sans-serif;
      letter-spacing: 2.1px; }
    .home_slide--caption.color--black span {
      color: #000; }
  .home_slide.slick-current.slick-active .home_slide--caption {
    animation-name: slider_caption;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-fill-mode: forwards; }

.widget_mob_smpl_slider_widg {
  position: relative; }
  @media (min-width: 641px) {
    .widget_mob_smpl_slider_widg {
      display: none; } }
  .widget_mob_smpl_slider_widg .slick-prev, .widget_mob_smpl_slider_widg .slick-next {
    font-size: 20px;
    color: #FFF;
    bottom: 20px;
    top: auto;
    transform: translate(0, 0);
    width: auto;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold; }
    .widget_mob_smpl_slider_widg .slick-prev:before, .widget_mob_smpl_slider_widg .slick-next:before {
      display: none; }
    .widget_mob_smpl_slider_widg .slick-prev .line_block, .widget_mob_smpl_slider_widg .slick-next .line_block {
      width: 19px;
      height: 2px;
      overflow: hidden;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 50%; }
      .widget_mob_smpl_slider_widg .slick-prev .line_block:before, .widget_mob_smpl_slider_widg .slick-prev .line_block:after, .widget_mob_smpl_slider_widg .slick-next .line_block:before, .widget_mob_smpl_slider_widg .slick-next .line_block:after {
        position: absolute;
        content: "";
        width: 19px;
        height: 2px;
        background: #FFF;
        top: 0;
        left: 0; }
      .widget_mob_smpl_slider_widg .slick-prev .line_block:before, .widget_mob_smpl_slider_widg .slick-next .line_block:before {
        animation: rightMove 3.6s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; }
      .widget_mob_smpl_slider_widg .slick-prev .line_block:after, .widget_mob_smpl_slider_widg .slick-next .line_block:after {
        opacity: 0;
        transition: all .3s; }
  .widget_mob_smpl_slider_widg .slick-prev {
    left: 30px;
    padding-right: 27px; }
    .widget_mob_smpl_slider_widg .slick-prev .line_block {
      left: auto;
      right: 0; }
  .widget_mob_smpl_slider_widg .slick-next {
    right: 30px;
    padding-left: 27px; }
  .widget_mob_smpl_slider_widg .simple_slider_title {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    background: #FFF;
    line-height: 1;
    padding: 24px 5px;
    color: #000;
    font-size: 73px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    max-width: 660px;
    width: calc(100% - 30px);
    text-transform: uppercase;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    transition: all .4s; }
    .widget_mob_smpl_slider_widg .simple_slider_title.hdn.vsbl {
      visibility: visible;
      opacity: 1; }
    @media (max-width: 767px) {
      .widget_mob_smpl_slider_widg .simple_slider_title {
        font-size: 48px;
        padding: 18px 5px; } }
    @media (max-width: 767px) {
      .widget_mob_smpl_slider_widg .simple_slider_title {
        font-size: 36px;
        padding: 12px 5px; } }
  .widget_mob_smpl_slider_widg .mobile_image_slide {
    background-size: cover;
    background-position: 50%;
    height: 100vh; }
    .widget_mob_smpl_slider_widg .mobile_image_slide img {
      display: none; }

.no-touch .widget_mob_smpl_slider_widg .slick-prev:hover, .no-touch .widget_mob_smpl_slider_widg .slick-next:hover {
  font-size: 13px; }
  .no-touch .widget_mob_smpl_slider_widg .slick-prev:hover .line_block:after, .no-touch .widget_mob_smpl_slider_widg .slick-next:hover .line_block:after {
    opacity: 1; }

.branding_animation_mobile_image,
.branding_animation_mobile_images {
  display: none; }

@media (max-width: 767px) {
  .widget_branding_anim_widg {
    overflow: hidden; }
  .branding_animation_mobile_images {
    height: 100vh;
    position: relative;
    display: block;
    z-index: 3; }
    .branding_animation_mobile_images .inner_block {
      height: inherit; }
    .branding_animation_mobile_images .flickity-slider {
      transform: none !important; }
    .branding_animation_mobile_images .flickity-viewport {
      height: 100% !important; }
    .branding_animation_mobile_images img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1.05);
      transition-duration: 1.5s;
      left: 0 !important;
      opacity: 0;
      transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) !important; }
      .branding_animation_mobile_images img.is-selected, .branding_animation_mobile_images img.slick-current {
        opacity: 1;
        transform: scale(1); }
  .branding_animation_mobile_images .play_btn {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s;
    margin: 0 auto;
    z-index: 9;
    opacity: 0.7;
    visibility: visible; }
    .branding_animation_mobile_images .play_btn:after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      width: 7px;
      height: 26px;
      background: #FFF;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%) translateX(-5px);
      box-shadow: 13px 0 0 0 #FFF;
      transition: all .3s;
      opacity: 0; }
    .branding_animation_mobile_images .play_btn:before {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      margin: 0 auto;
      border-style: solid;
      border-width: 15px 0 15px 26px;
      border-color: transparent transparent transparent #fff;
      transition: all .3s;
      opacity: 1; }
  .no-touch .branding_animation_mobile_images .play_btn:hover:before {
    transform: translateY(-50%) scale(1.2); } }

@media (max-width: 767px) {
  .mobile_branding_video_section .branding_animation_mobile_popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    transition: all .4s;
    padding: 30px;
    transform: translate3d(0, 0, 0); }
    .mobile_branding_video_section .branding_animation_mobile_popup.active {
      visibility: visible;
      opacity: 1;
      overflow: hidden; }
      .mobile_branding_video_section .branding_animation_mobile_popup.active .branding_popup__close {
        display: block;
        visibility: visible; }
      .mobile_branding_video_section .branding_animation_mobile_popup.active video {
        display: block; }
    .mobile_branding_video_section .branding_animation_mobile_popup iframe {
      border: none;
      width: 100%;
      height: 100%; }
    .mobile_branding_video_section .branding_animation_mobile_popup .branding_vimeo-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; } }

.mobile_branding_video_section .branding_animation_mobile_popup .branding_popup__close {
  display: none;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  visibility: hidden;
  transition: all .3s;
  z-index: 9;
  display: none; }
  .mobile_branding_video_section .branding_animation_mobile_popup .branding_popup__close:before, .mobile_branding_video_section .branding_animation_mobile_popup .branding_popup__close:after {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    left: 0;
    right: 0;
    height: 2px;
    width: 15px;
    background: #FFF;
    margin: 0 auto; }
  .mobile_branding_video_section .branding_animation_mobile_popup .branding_popup__close:before {
    transform: translateY(-50%) rotate(-45deg); }

.mobile_branding_video_section {
  position: relative; }
  .mobile_branding_video_section .widget_title {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    background: #FFF;
    line-height: 1;
    color: #000;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    max-width: 660px;
    width: calc(100% - 30px);
    text-transform: uppercase;
    text-align: center;
    font-size: 48px;
    padding: 18px 5px;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    transition: all .4s; }
    @media (max-width: 767px) {
      .mobile_branding_video_section .widget_title {
        font-size: 48px;
        padding: 18px 5px; } }
    @media (max-width: 640px) {
      .mobile_branding_video_section .widget_title {
        font-size: 36px;
        padding: 12px 5px; } }
    .mobile_branding_video_section .widget_title.hdn.vsbl {
      visibility: visible;
      opacity: 1; }
  @media (min-width: 768px) {
    .mobile_branding_video_section {
      display: none; } }
  @media (max-width: 767px) {
    .mobile_branding_video_section {
      position: relative;
      height: 100vh; }
      .mobile_branding_video_section video {
        display: none;
        object-fit: cover;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0; } }

.branding_animation_widget,
.vimeo_animation_widget {
  position: relative;
  height: 100vh;
  /* Could Use thise as well for Individual Controls */ }
  @media (min-width: 768px) {
    .branding_animation_widget,
    .vimeo_animation_widget {
      overflow: hidden; }
      .branding_animation_widget .music_icon,
      .vimeo_animation_widget .music_icon {
        width: 100%;
        height: 100%;
        opacity: 0;
        left: 0;
        top: 0;
        bottom: 0;
        transform: translateY(0); }
        .branding_animation_widget .music_icon.pause,
        .vimeo_animation_widget .music_icon.pause {
          cursor: url("../img/SoundIcon_OffState/WED_SoundIcon_Mouse_OffState.svg"), auto; }
        .branding_animation_widget .music_icon.play,
        .vimeo_animation_widget .music_icon.play {
          cursor: url("../img/SoundIcon_OffState/WED_SoundIcon_Mouse_OffState.svg"), auto; } }
  @media (max-width: 767px) {
    .branding_animation_widget,
    .vimeo_animation_widget {
      display: none; } }
  .branding_animation_widget .brand_video_section,
  .vimeo_animation_widget .brand_video_section {
    position: relative; }
    .branding_animation_widget .brand_video_section .widget_title,
    .vimeo_animation_widget .brand_video_section .widget_title {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      background: #FFF;
      line-height: 1;
      padding: 24px 5px;
      color: #000;
      font-size: 73px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
      max-width: 660px;
      width: calc(100% - 30px);
      text-transform: uppercase;
      text-align: center;
      opacity: 0;
      visibility: hidden;
      transition: all .4s; }
      .branding_animation_widget .brand_video_section .widget_title.hdn.vsbl,
      .vimeo_animation_widget .brand_video_section .widget_title.hdn.vsbl {
        visibility: visible;
        opacity: 1; }
  .branding_animation_widget .brand_video_section,
  .branding_animation_widget .mobile_branding_video_section,
  .vimeo_animation_widget .brand_video_section,
  .vimeo_animation_widget .mobile_branding_video_section {
    position: relative;
    height: inherit;
    overflow: hidden; }
    @media (max-width: 767px) {
      .branding_animation_widget .brand_video_section,
      .branding_animation_widget .mobile_branding_video_section,
      .vimeo_animation_widget .brand_video_section,
      .vimeo_animation_widget .mobile_branding_video_section {
        margin-bottom: -7px; } }
  .branding_animation_widget .myVideo,
  .vimeo_animation_widget .myVideo {
    object-fit: cover;
    object-position: 50% 50%;
    display: block;
    transition: all .3s; }
    @media (min-width: 768px) {
      .branding_animation_widget .myVideo,
      .vimeo_animation_widget .myVideo {
        width: 100%;
        height: 100%;
        z-index: -1; } }
    @media (max-width: 767px) {
      .branding_animation_widget .myVideo,
      .vimeo_animation_widget .myVideo {
        opacity: 1;
        height: auto;
        width: 100%;
        display: none; }
        .branding_animation_widget .myVideo:not(:first-child),
        .vimeo_animation_widget .myVideo:not(:first-child) {
          display: none; } }
    .branding_animation_widget .myVideo.vsbl, .branding_animation_widget .myVideo.last_vsbl,
    .vimeo_animation_widget .myVideo.vsbl,
    .vimeo_animation_widget .myVideo.last_vsbl {
      opacity: 1; }
  @media (min-width: 768px) {
    .branding_animation_widget .mobile_video,
    .vimeo_animation_widget .mobile_video {
      display: none !important; } }
  @media (max-width: 767px) {
    .branding_animation_widget .mobile_video,
    .vimeo_animation_widget .mobile_video {
      object-fit: cover;
      display: block;
      transition: all .3s;
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%; }
      .branding_animation_widget .mobile_video:not(:first-child),
      .vimeo_animation_widget .mobile_video:not(:first-child) {
        display: none; } }

/* end */
/* module 4 styles */
.vimeo_modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  padding: 30px;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all ease .3s;
  transform: translate3d(0, 0, 0); }
  .vimeo_modal.active {
    opacity: 1;
    visibility: visible;
    z-index: 99999; }
  .vimeo_modal .vimeo-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .vimeo_modal .vimeo-wrapper iframe {
      border: none;
      width: 100%;
      height: 100%; }
  .vimeo_modal_close {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    transition: all .3s; }
    .vimeo_modal_close:before, .vimeo_modal_close:after {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      left: 0;
      right: 0;
      height: 2px;
      width: 15px;
      background: #FFF;
      margin: 0 auto; }
    .vimeo_modal_close:before {
      transform: translateY(-50%) rotate(-45deg); }

@media (min-width: 768px) {
  .vimeo_animation_widget {
    background-image: none !important; } }

@media (max-width: 767px) {
  .vimeo_animation_widget {
    display: block;
    background-size: cover;
    background-position: 50%; } }

.vimeo_animation_widget .myVideo_vimeo {
  object-fit: cover;
  object-position: center center;
  display: block;
  height: 100%;
  transition: all .3s; }
  @media (min-width: 768px) {
    .vimeo_animation_widget .myVideo_vimeo {
      width: 100%;
      height: 100%;
      z-index: -1; } }
  @media (max-width: 767px) {
    .vimeo_animation_widget .myVideo_vimeo {
      opacity: 1;
      width: 100%;
      display: none !important; } }
  .vimeo_animation_widget .myVideo_vimeo.vsbl, .vimeo_animation_widget .myVideo_vimeo.last_vsbl {
    opacity: 1; }

.vimeo_animation_widget .vimeo_mob_video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  padding: 30px;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all ease .3s;
  opacity: 1;
  visibility: visible; }
  @media (min-width: 768px) {
    .vimeo_animation_widget .vimeo_mob_video {
      display: none; } }
  .vimeo_animation_widget .vimeo_mob_video .vimeo-wrapper {
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .vimeo_animation_widget .vimeo_mob_video .vimeo-wrapper iframe {
      border: none;
      width: 100%;
      height: 100%; }

@media (max-width: 767px) {
  .vimeo_animation_widget .music_icon {
    display: none; } }

@media (max-width: 767px) {
  .vimeo_animation_widget .vimeo_video_play_btn {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s;
    margin: 0 auto;
    z-index: 9;
    visibility: visible; }
    .vimeo_animation_widget .vimeo_video_play_btn:before {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 0;
      height: 0;
      margin: 0 auto;
      border-style: solid;
      border-width: 15px 0 15px 26px;
      border-color: transparent transparent transparent #fff;
      transition: all .3s;
      opacity: 1; } }

.vimeo_animation_widget .vimeo_video_section {
  height: 100vh; }
  @media (max-width: 767px) {
    .vimeo_animation_widget .vimeo_video_section {
      visibility: hidden;
      opacity: 0;
      transition: all .3s; }
      .vimeo_animation_widget .vimeo_video_section_close {
        width: 45px;
        height: 45px;
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        visibility: hidden;
        transition: all .3s;
        z-index: 9;
        display: none; }
        .vimeo_animation_widget .vimeo_video_section_close:before, .vimeo_animation_widget .vimeo_video_section_close:after {
          position: absolute;
          content: "";
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          left: 0;
          right: 0;
          height: 2px;
          width: 15px;
          background: #FFF;
          margin: 0 auto; }
        .vimeo_animation_widget .vimeo_video_section_close:before {
          transform: translateY(-50%) rotate(-45deg); }
      .vimeo_animation_widget .vimeo_video_section.popup_active {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        visibility: visible;
        opacity: 1;
        z-index: 9999;
        background: #000;
        transform: translate3d(0, 0, 0); }
        .vimeo_animation_widget .vimeo_video_section.popup_active .vimeo_video_section_close {
          display: block;
          visibility: visible; } }
  .vimeo_animation_widget .vimeo_video_section .widget_title {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    background: #FFF;
    line-height: 1;
    padding: 24px 5px;
    color: #000;
    font-size: 73px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    max-width: 660px;
    width: calc(100% - 30px);
    text-transform: uppercase;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all .4s; }
    .vimeo_animation_widget .vimeo_video_section .widget_title.hdn.vsbl {
      visibility: visible;
      opacity: 1; }

.widget_video_cntn_widg {
  position: relative; }
  .widget_video_cntn_widg .vimeo_widget_title {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    background: #FFF;
    line-height: 1;
    padding: 24px 5px;
    color: #000;
    font-size: 73px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    max-width: 660px;
    width: calc(100% - 30px);
    text-transform: uppercase;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: all .4s; }
    .widget_video_cntn_widg .vimeo_widget_title.hdn.vsbl {
      visibility: visible;
      opacity: 1; }
    @media (max-width: 767px) {
      .widget_video_cntn_widg .vimeo_widget_title {
        font-size: 48px;
        padding: 18px 5px; } }
    @media (max-width: 767px) {
      .widget_video_cntn_widg .vimeo_widget_title {
        font-size: 36px;
        padding: 12px 5px; } }

.video_section {
  position: relative;
  margin-bottom: -1px; }
  @media (min-width: 641px) {
    .video_section {
      margin-bottom: -7px;
      margin-top: -5px; } }
  @media (max-width: 640px) {
    .video_section > .play_button {
      display: none; } }
  .video_section .play_button {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 90px;
    height: 60px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s;
    margin: 0 auto;
    z-index: 9;
    visibility: hidden;
    opacity: 0;
    display: none; }
    @media (max-width: 640px) {
      .video_section .play_button {
        opacity: 0.7;
        visibility: visible; } }
    .video_section .play_button:after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      width: 7px;
      height: 26px;
      background: #FFF;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%) translateX(-5px);
      box-shadow: 13px 0 0 0 #FFF;
      transition: all .3s; }
    .video_section .play_button:before {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      margin: 0 auto;
      border-style: solid;
      border-width: 15px 0 15px 26px;
      border-color: transparent transparent transparent #fff;
      transition: all .3s;
      opacity: 0; }
    .video_section .play_button.pause:before {
      opacity: 1; }
    .video_section .play_button.pause:after {
      opacity: 0; }
  @media (min-width: 641px) {
    .no-touch .video_section .play_button {
      opacity: .7;
      visibility: visible; }
      .no-touch .video_section .play_button:hover {
        opacity: 1; } }
  .video_section .all_videos_btn {
    position: absolute;
    max-width: 230px;
    width: 100%;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    color: #FFF;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 2.2px;
    display: inline-block;
    text-align: center;
    font-weight: 700;
    padding: 11px;
    cursor: pointer;
    z-index: 10; }

.video_slider {
  overflow: hidden;
  user-select: auto;
  display: none; }
  @media (min-width: 641px) {
    .video_slider {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin-top: -3px; } }
  @media (max-width: 640px) {
    .video_slider {
      display: none; } }

@media (min-width: 641px) {
  .mobile_vimeo_static_img {
    display: none; } }

@media (max-width: 640px) {
  .mobile_vimeo_static_img {
    display: none;
    position: relative; }
    .mobile_vimeo_static_img .play_btn {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 90px;
      height: 60px;
      border-radius: 5px;
      cursor: pointer;
      transition: all .3s;
      margin: 0 auto;
      z-index: 9; } }
    @media (max-width: 640px) and (max-width: 640px) {
      .mobile_vimeo_static_img .play_btn {
        opacity: 0.7;
        visibility: visible; } }

@media (max-width: 640px) {
      .mobile_vimeo_static_img .play_btn:after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        width: 7px;
        height: 26px;
        background: #FFF;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%) translateX(-5px);
        box-shadow: 13px 0 0 0 #FFF;
        transition: all .3s;
        opacity: 0; }
      .mobile_vimeo_static_img .play_btn:before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        margin: 0 auto;
        border-style: solid;
        border-width: 15px 0 15px 26px;
        border-color: transparent transparent transparent #fff;
        transition: all .3s;
        opacity: 1; }
    .no-touch .mobile_vimeo_static_img .play_btn:hover:before {
      transform: translateY(-50%) scale(1.2); }
    .mobile_vimeo_static_img img {
      max-width: 100%;
      width: 100%;
      display: block; } }

.mobile_vimeo_slider {
  display: none; }
  @media (min-width: 641px) {
    .mobile_vimeo_slider {
      display: none; } }
  .mobile_vimeo_slider .video_slide {
    position: relative;
    user-select: auto; }
    .mobile_vimeo_slider .video_slide .play_button {
      display: block; }
  .no-touch .mobile_vimeo_slider .video_slide:hover .play_button {
    opacity: .7;
    visibility: visible; }
    .no-touch .mobile_vimeo_slider .video_slide:hover .play_button:hover {
      opacity: 1; }

.video_slide {
  border: none !important;
  user-select: auto; }
  @media (min-width: 641px) {
    .video_slide {
      position: relative; } }
  .video_slide .vimeo-wrapper iframe {
    width: 100vw;
    height: 57.25vw;
    border: none; }
    @media (min-width: 641px) {
      .video_slide .vimeo-wrapper iframe {
        margin-top: -3px;
        margin-bottom: -8px;
        position: relative;
        z-index: 999; } }
    @media (max-width: 640px) {
      .video_slide .vimeo-wrapper iframe {
        display: block;
        height: 56.25vw; } }
    .video_slide .vimeo-wrapper iframe .player .vp-controls {
      visibility: hidden;
      opacity: 0;
      display: none !important; }
  .video_slide .no-touch .vimeo-wrapper iframe:hover + .play_button {
    visibility: visible;
    opacity: 1; }

.video_slider__nav {
  max-width: 676px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #000;
  padding: 33px 15px 60px;
  margin: 0 auto;
  transform: translateY(100%);
  transition: all .8s;
  z-index: 9;
  visibility: hidden; }
  .video_slider__nav.opnd {
    transform: translateY(0);
    visibility: visible; }
  .video_slider__nav .custom-slick-arrow {
    outline: none;
    cursor: pointer;
    font-size: 0;
    position: absolute;
    top: 61px;
    max-width: 12px;
    z-index: 2; }
  .video_slider__nav .custom-slick-next {
    right: 30px; }
    @media (max-width: 640px) {
      .video_slider__nav .custom-slick-next {
        top: auto;
        bottom: 43px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        max-width: 100%;
        background: #000;
        height: 12px; }
        .video_slider__nav .custom-slick-next img {
          width: 12px;
          transform: rotate(90deg); } }
  .video_slider__nav .custom-slick-prev {
    left: 30px; }
    .video_slider__nav .custom-slick-prev img {
      transform: rotate(180deg); }
    @media (max-width: 640px) {
      .video_slider__nav .custom-slick-prev {
        display: none !important; } }
  .video_slider__nav .inner_video_slider__nav {
    max-width: 580px;
    margin: 0 auto; }
  .video_slider__nav .nav_slide {
    cursor: pointer; }
    @media (max-width: 640px) {
      .video_slider__nav .nav_slide {
        margin-top: 6px;
        margin-bottom: 16px;
        min-height: 170px; } }
    .video_slider__nav .nav_slide--image {
      max-width: 150px;
      height: 85px;
      margin: 0 auto 10px;
      overflow: hidden; }
      @media (max-width: 640px) {
        .video_slider__nav .nav_slide--image {
          max-width: 230px;
          height: 120px; } }
      .video_slider__nav .nav_slide--image img {
        object-fit: cover;
        width: 100%;
        height: 100%; }
    .video_slider__nav .nav_slide--firsttitle, .video_slider__nav .nav_slide--subtitle {
      color: #FFF;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold; }
    .video_slider__nav .nav_slide--firsttitle {
      font-family: "gtpressura", Helvetica, Roboto, Arial, sans-serif;
      font-size: 17px;
      line-height: 1.2;
      margin-bottom: 3px; }
    .video_slider__nav .nav_slide--subtitle {
      color: #FFF;
      text-transform: uppercase;
      text-align: center;
      font-size: 11px;
      letter-spacing: 1.75px; }

/* END module 4 styles */
/* module 5 styles */
@keyframes rightMove {
  from {
    transform: translateX(-100%); }
  to {
    transform: translateX(100%); } }

.module_5 {
  background: #000;
  height: 100vh;
  position: relative;
  display: flex; }
  @media (max-width: 767px) {
    .module_5 {
      min-height: 100vh;
      height: auto;
      padding: 50px 0; } }
  @media (max-width: 640px) {
    .module_5 {
      background-image: none !important; } }
  .module_5__caption {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center; }
    @media (min-width: 768px) {
      .module_5__caption {
        flex-direction: row-reverse; } }
    @media (max-width: 767px) {
      .module_5__caption {
        flex-wrap: wrap;
        padding: 0; } }
    .module_5__caption .inner_capt {
      width: 36.5%;
      align-self: center; }
      @media (max-width: 1024px) {
        .module_5__caption .inner_capt {
          flex-basis: 45%; } }
      @media (max-width: 767px) {
        .module_5__caption .inner_capt {
          flex-basis: 100%;
          max-width: 430px;
          padding: 0 45px; } }
      @media (max-width: 640px) {
        .module_5__caption .inner_capt {
          width: 100%;
          padding: 0 45px; } }
      @media (max-width: 480px) {
        .module_5__caption .inner_capt {
          width: 100%;
          padding: 0 2.8125rem; } }
      @media (max-width: 360px) {
        .module_5__caption .inner_capt {
          padding: 0 1.875rem; } }
    .module_5__caption .img_block {
      flex-basis: 63.5%;
      text-align: center;
      padding: 0 30px 0 0;
      overflow: hidden; }
      @media (max-width: 1024px) {
        .module_5__caption .img_block {
          flex-basis: 55%; } }
      @media (max-width: 767px) {
        .module_5__caption .img_block {
          flex-basis: 100%;
          padding: 0; } }
      .module_5__caption .img_block img {
        max-height: 100vh; }
        @media (min-width: 1025px) {
          .module_5__caption .img_block img {
            transform: scale(1.1); } }
    .module_5__caption h2 {
      color: #FFF;
      text-transform: uppercase;
      font-size: 70px;
      line-height: .95;
      letter-spacing: -1px;
      margin-bottom: 20px; }
      @media (max-width: 1100px) {
        .module_5__caption h2 {
          font-size: 65px; } }
      @media (max-width: 1024px) {
        .module_5__caption h2 {
          font-size: 62px; } }
      @media (max-width: 991px) {
        .module_5__caption h2 {
          font-size: 60px; } }
      @media (max-width: 767px) {
        .module_5__caption h2 {
          font-size: 57px; } }
      @media (max-width: 640px) {
        .module_5__caption h2 {
          font-size: 57px; } }
      @media (max-width: 440px) {
        .module_5__caption h2 {
          font-size: 49px; } }
      @media (max-width: 360px) {
        .module_5__caption h2 {
          font-size: 47px; } }
    .module_5__caption .mod_5_btn_link {
      color: #FFF;
      text-transform: uppercase;
      font-size: 11px;
      display: block;
      letter-spacing: 2.3px;
      font-weight: bold;
      padding: 10px;
      width: 100%;
      background: blue;
      position: relative;
      padding-left: 27px;
      cursor: pointer;
      min-height: 17px; }
      .module_5__caption .mod_5_btn_link .line_block {
        width: 19px;
        height: 2px;
        overflow: hidden;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%; }
        .module_5__caption .mod_5_btn_link .line_block:after, .module_5__caption .mod_5_btn_link .line_block:before {
          position: absolute;
          content: "";
          width: 19px;
          height: 2px;
          background: #FFF;
          top: 0;
          left: 0; }
        .module_5__caption .mod_5_btn_link .line_block:before {
          animation: rightMove 3.6s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; }
        .module_5__caption .mod_5_btn_link .line_block:after {
          opacity: 0;
          transition: all .3s; }
    .module_5__caption .button_block {
      padding-left: 6px; }
      .module_5__caption .button_block .module_5__caption_ink {
        text-transform: uppercase;
        color: #FFF;
        font-size: 10px;
        letter-spacing: 2.3px;
        font-weight: bold;
        position: relative;
        padding-left: 27px;
        cursor: pointer;
        min-height: 17px;
        z-index: 10; }
        .module_5__caption .button_block .module_5__caption_ink .line_block {
          width: 19px;
          height: 2px;
          overflow: hidden;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 50%; }
          .module_5__caption .button_block .module_5__caption_ink .line_block:after, .module_5__caption .button_block .module_5__caption_ink .line_block:before {
            position: absolute;
            content: "";
            width: 19px;
            height: 2px;
            background: #FFF;
            top: 0;
            left: 0; }
          .module_5__caption .button_block .module_5__caption_ink .line_block:before {
            animation: rightMove 3.6s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; }
          .module_5__caption .button_block .module_5__caption_ink .line_block:after {
            opacity: 0;
            transition: all .3s; }

@media (min-width: 641px) {
  .no-touch .module_5__caption .button_block .module_5__caption_ink:hover {
    font-size: 11px; }
    .no-touch .module_5__caption .button_block .module_5__caption_ink:hover .line_block:after {
      opacity: 1; } }

.module_5_popup {
  background: #000;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  transition: all .3s;
  width: 100%;
  height: 100vh; }
  .module_5_popup.popup_visible {
    visibility: visible;
    opacity: 1; }
  .module_5_popup__close {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    visibility: hidden;
    transition: all .3s; }
    .module_5_popup__close.show {
      visibility: visible; }
    .module_5_popup__close:before, .module_5_popup__close:after {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      left: 0;
      right: 0;
      height: 2px;
      width: 15px;
      background: #FFF;
      margin: 0 auto; }
    .module_5_popup__close:before {
      transform: translateY(-50%) rotate(-45deg); }
  .module_5_popup .inner_block_imgs {
    align-items: center;
    max-height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden; }
    .module_5_popup .inner_block_imgs div {
      display: none; }
    .module_5_popup .inner_block_imgs svg {
      max-width: 100%;
      max-height: 100vh; }
    .module_5_popup .inner_block_imgs img {
      display: none;
      width: 100%; }
    @media (min-width: 1025px) {
      .module_5_popup .inner_block_imgs .desktop_img {
        display: block;
        width: 100%; } }
    @media (max-width: 1024px) and (min-width: 768px) {
      .module_5_popup .inner_block_imgs .tablet_img {
        display: block;
        width: 100%; } }
    @media (max-width: 767px) {
      .module_5_popup .inner_block_imgs .mobile_img {
        display: block;
        width: 100%; } }
  .module_5_popup .inner_block {
    align-items: center;
    max-height: 100%;
    padding: 0 35px;
    display: flex;
    align-items: center; }
    @media (max-width: 767px) {
      .module_5_popup .inner_block {
        padding: 0 20px; } }
    @media (max-width: 640px) {
      .module_5_popup .inner_block {
        padding: 0 15px; } }
  .module_5_popup svg > g {
    opacity: 0; }
    .module_5_popup svg > g.visible {
      opacity: 1; }
  .module_5_popup p {
    flex-basis: 100%;
    text-align: justify;
    text-transform: uppercase;
    font-family: "gtpressura", Helvetica, Roboto, Arial, sans-serif;
    color: #FFF;
    line-height: 0.97; }
    .module_5_popup p:last-child {
      margin-bottom: 0; }
    .module_5_popup p span {
      opacity: 0; }
      .module_5_popup p span.visible {
        opacity: 1; }

/* END module 5 styles */
/* spotify widget - module 7 */
.widget_potify_widg {
  position: relative;
  z-index: 9; }

.spotify_sect {
  background: #000;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0; }
  .spotify_sect__row {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    color: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 767px) {
      .spotify_sect__row {
        flex-wrap: wrap;
        justify-content: center; } }
    .spotify_sect__row .spotify_img {
      flex-basis: 58%;
      padding-right: 30px; }
      @media (min-width: 1025px) {
        .spotify_sect__row .spotify_img {
          transform: scale(1.13); } }
      @media (max-width: 767px) {
        .spotify_sect__row .spotify_img {
          flex-basis: 100%;
          padding-right: 0;
          padding-bottom: 56.25%;
          position: relative;
          transform: scale(1.15); } }
      .spotify_sect__row .spotify_img img {
        max-width: 670px;
        height: auto;
        width: 100%; }
      @media (max-width: 767px) {
        .spotify_sect__row .spotify_img .spotify_img_el {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0; } }
      .spotify_sect__row .spotify_img img:not(.spotify_img_el) {
        display: none !important; }
    .spotify_sect__row .spotify_box {
      flex-basis: 37%; }
      @media (max-width: 767px) {
        .spotify_sect__row .spotify_box {
          flex-basis: 100%;
          padding: 0 1.875rem;
          margin-top: 80px; } }
      @media (max-width: 360px) {
        .spotify_sect__row .spotify_box {
          padding: 0 0.9375rem; } }
      .spotify_sect__row .spotify_box--inner {
        max-width: 360px; }
        @media (max-width: 640px) {
          .spotify_sect__row .spotify_box--inner {
            max-width: 350px; } }
    .spotify_sect__row a {
      color: #FFF; }
    .spotify_sect__row .new_spotifyfeed,
    .spotify_sect__row .spotifyfeed {
      font-size: 27px;
      font-weight: bold;
      font-family: "gtpressura", Helvetica, Roboto, Arial, sans-serif;
      text-transform: uppercase;
      letter-spacing: -1px;
      line-height: 0.9; }
      @media (max-width: 991px) {
        .spotify_sect__row .new_spotifyfeed,
        .spotify_sect__row .spotifyfeed {
          font-size: 24px; } }
      @media (max-width: 767px) {
        .spotify_sect__row .new_spotifyfeed,
        .spotify_sect__row .spotifyfeed {
          font-size: 20px; } }
      @media (max-width: 640px) {
        .spotify_sect__row .new_spotifyfeed,
        .spotify_sect__row .spotifyfeed {
          letter-spacing: -.2px; } }
      .spotify_sect__row .new_spotifyfeed span,
      .spotify_sect__row .spotifyfeed span {
        font-size: 75px;
        display: block;
        margin-bottom: 4px;
        letter-spacing: -2.2px; }
        @media (max-width: 991px) {
          .spotify_sect__row .new_spotifyfeed span,
          .spotify_sect__row .spotifyfeed span {
            font-size: 55px; } }
        @media (max-width: 767px) {
          .spotify_sect__row .new_spotifyfeed span,
          .spotify_sect__row .spotifyfeed span {
            font-size: 58px; } }
        @media (max-width: 640px) {
          .spotify_sect__row .new_spotifyfeed span,
          .spotify_sect__row .spotifyfeed span {
            letter-spacing: -.2px; } }
    .spotify_sect__row .follow-link {
      font-family: "TTNorms", Helvetica, Roboto, Arial, sans-serif;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: bold;
      margin-top: 32px;
      display: inline-block;
      letter-spacing: 1.6px;
      padding-left: 27px;
      position: relative;
      min-height: 20px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 9; }
      .spotify_sect__row .follow-link .line_block {
        width: 19px;
        height: 2px;
        overflow: hidden;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%; }
        .spotify_sect__row .follow-link .line_block:after, .spotify_sect__row .follow-link .line_block:before {
          position: absolute;
          content: "";
          width: 19px;
          height: 2px;
          background: #FFF;
          top: 0;
          left: 0; }
        .spotify_sect__row .follow-link .line_block:before {
          animation: rightMove 3.6s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; }
        .spotify_sect__row .follow-link .line_block:after {
          opacity: 0;
          transition: all .3s; }

.no-touch .spotify_sect__row .follow-link:hover {
  font-size: 11px; }

.no-touch .spotify_sect__row .follow-link:hover {
  font-size: 11px; }
  .no-touch .spotify_sect__row .follow-link:hover .line_block:after {
    opacity: 1; }

/* END spotify widget - module 7 */
.instagram_widget .insta__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media (max-width: 480px) {
    .instagram_widget .insta__grid {
      display: block;
      font-size: 0;
      text-align: center; } }
  .instagram_widget .insta__grid--item {
    flex: 1 1 160px;
    flex-basis: calc(100% / 6); }
    @media (max-width: 900px) {
      .instagram_widget .insta__grid--item {
        flex: 1 1 250px; } }
    @media (max-width: 767px) {
      .instagram_widget .insta__grid--item {
        flex: 1 1 200px; } }
    @media (max-width: 560px) {
      .instagram_widget .insta__grid--item {
        width: 25%; } }
    @media (max-width: 480px) {
      .instagram_widget .insta__grid--item {
        max-width: 50%;
        display: none; }
        .instagram_widget .insta__grid--item:first-child, .instagram_widget .insta__grid--item:nth-child(2) {
          display: inline-block;
          width: 100%; } }
    @media (max-width: 380px) {
      .instagram_widget .insta__grid--item {
        width: 50%;
        float: none; } }
    .instagram_widget .insta__grid--item a {
      display: block;
      overflow: hidden; }
      .instagram_widget .insta__grid--item a span {
        width: 100%;
        display: block;
        transition: all .8s ease-in-out;
        padding-bottom: 100%;
        background-size: cover;
        background-position: 50%;
        backface-visibility: hidden; }

.say-hello-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  color: #FFF;
  background: #000;
  z-index: 100;
  min-height: 100vh;
  padding: 30px; }
  @media (max-width: 1024px) {
    .say-hello-popup {
      padding: 15px; } }
  .say-hello-popup.opnd {
    opacity: 1;
    visibility: visible; }
  .say-hello-popup__close {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    transition: all .3s;
    background: #000;
    z-index: 9; }
    .say-hello-popup__close:before, .say-hello-popup__close:after {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      left: 0;
      right: 0;
      height: 2px;
      width: 15px;
      background: #FFF;
      margin: 0 auto; }
    .say-hello-popup__close:before {
      transform: translateY(-50%) rotate(-45deg); }
  .say-hello-popup .inner_block {
    position: relative;
    height: 100%;
    overflow: auto;
    max-width: 1125px;
    margin: 0 auto; }
    @media (max-width: 1400px) {
      .say-hello-popup .inner_block {
        max-width: 100%; } }
    @media (min-width: 768px) {
      .say-hello-popup .inner_block > div {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        align-items: center; } }
    @media (max-width: 767px) {
      .say-hello-popup .inner_block > div {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        max-height: 100%; } }
    @media (min-width: 641px) {
      .say-hello-popup .inner_block > div > div {
        display: flex; } }
    @media (max-width: 640px) {
      .say-hello-popup .inner_block > div > div {
        padding-top: 15px; } }
  .say-hello-popup .title_block {
    max-width: 49%;
    flex: 1 1 49%;
    padding: 15px 0; }
    @media (max-width: 767px) {
      .say-hello-popup .title_block {
        padding: 15px 0;
        flex: 1 1 100%;
        max-width: 100%; } }
    .say-hello-popup .title_block h2 {
      color: #FFF;
      font-size: 93px;
      line-height: .85;
      font-family: "gtpressura", Helvetica, Roboto, Arial, sans-serif;
      text-transform: uppercase; }
      @media (max-width: 1600px) {
        .say-hello-popup .title_block h2 {
          font-size: 80px; } }
      @media (max-width: 1400px) {
        .say-hello-popup .title_block h2 {
          font-size: 70px;
          line-height: 1; } }
      @media (max-width: 991px) {
        .say-hello-popup .title_block h2 {
          font-size: 65px; } }
      @media (max-width: 767px) {
        .say-hello-popup .title_block h2 {
          font-size: 58px; } }
      @media (max-width: 640px) {
        .say-hello-popup .title_block h2 {
          font-size: 45px; } }
      @media (max-width: 480px) {
        .say-hello-popup .title_block h2 {
          font-size: 30px; } }
  .say-hello-popup .form_block {
    max-width: 46.5%;
    flex: 1 1 46.5%;
    padding: 15px; }
    @media (max-width: 767px) {
      .say-hello-popup .form_block {
        padding: 15px 0;
        flex: 1 1 100%;
        max-width: 100%; } }
    .say-hello-popup .form_block form label {
      display: block;
      width: 100%;
      float: left;
      margin-bottom: 20px;
      position: relative; }
      @media (min-width: 881px) {
        .say-hello-popup .form_block form label:first-of-type {
          width: calc(50% - 10px); }
        .say-hello-popup .form_block form label:nth-of-type(2) {
          width: calc(50% - 10px);
          margin-left: 20px; } }
      .say-hello-popup .form_block form label > span {
        width: 100%;
        display: block; }
      .say-hello-popup .form_block form label span.wpcf7-not-valid-tip {
        font-size: 16px;
        position: absolute;
        left: 0;
        right: 0;
        top: 97%; }
      .say-hello-popup .form_block form label input[type='text'],
      .say-hello-popup .form_block form label input[type='number'],
      .say-hello-popup .form_block form label input[type='password'],
      .say-hello-popup .form_block form label input[type='email'] {
        width: 100%;
        display: block;
        outline: none;
        padding: 10px 5px;
        height: 54px;
        text-transform: uppercase;
        font-family: "TTNorms", Helvetica, Roboto, Arial, sans-serif;
        font-weight: bold;
        font-size: 10px;
        letter-spacing: 2px; }
        @media (max-width: 1024px) {
          .say-hello-popup .form_block form label input[type='text'],
          .say-hello-popup .form_block form label input[type='number'],
          .say-hello-popup .form_block form label input[type='password'],
          .say-hello-popup .form_block form label input[type='email'] {
            height: 38px;
            padding: 5px 10px; } }
        @media (max-width: 767px) {
          .say-hello-popup .form_block form label input[type='text'],
          .say-hello-popup .form_block form label input[type='number'],
          .say-hello-popup .form_block form label input[type='password'],
          .say-hello-popup .form_block form label input[type='email'] {
            height: 36px; } }
        @media (max-width: 640px) {
          .say-hello-popup .form_block form label input[type='text'],
          .say-hello-popup .form_block form label input[type='number'],
          .say-hello-popup .form_block form label input[type='password'],
          .say-hello-popup .form_block form label input[type='email'] {
            height: 34px; } }
        @media (max-width: 480px) {
          .say-hello-popup .form_block form label input[type='text'],
          .say-hello-popup .form_block form label input[type='number'],
          .say-hello-popup .form_block form label input[type='password'],
          .say-hello-popup .form_block form label input[type='email'] {
            height: 30px; } }
      .say-hello-popup .form_block form label textarea {
        width: 100%;
        display: block;
        resize: none;
        outline: none;
        height: 132px;
        padding: 5px 10px;
        font-family: "TTNorms", Helvetica, Roboto, Arial, sans-serif;
        font-weight: bold;
        font-size: 10px;
        letter-spacing: 2px;
        text-transform: uppercase; }
        @media (max-width: 767px) {
          .say-hello-popup .form_block form label textarea {
            height: 120px; } }
        @media (max-width: 640px) {
          .say-hello-popup .form_block form label textarea {
            height: 110px; } }
        @media (max-width: 480px) {
          .say-hello-popup .form_block form label textarea {
            height: 90px; } }
    .say-hello-popup .form_block form input[type='submit'] {
      background: transparent;
      color: #FFF;
      border: none;
      cursor: pointer;
      text-transform: uppercase;
      letter-spacing: 1px;
      outline: none; }
    .say-hello-popup .form_block form button {
      background: transparent;
      color: #FFF;
      border: none;
      cursor: pointer;
      text-transform: uppercase;
      letter-spacing: 2px;
      position: relative;
      z-index: 9;
      padding-left: 27px;
      outline: none;
      font-size: 10px;
      font-family: "TTNorms", Helvetica, Roboto, Arial, sans-serif;
      font-weight: bold;
      transform: translateY(-7px); }
      .say-hello-popup .form_block form button .line_block {
        width: 19px;
        height: 2px;
        overflow: hidden;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%; }
        .say-hello-popup .form_block form button .line_block:before, .say-hello-popup .form_block form button .line_block:after {
          position: absolute;
          content: "";
          width: 19px;
          height: 2px;
          background: #FFF;
          top: 0;
          left: 0; }
        .say-hello-popup .form_block form button .line_block:before {
          animation: rightMove 3.6s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; }
        .say-hello-popup .form_block form button .line_block:after {
          opacity: 0;
          transition: all .3s; }
    .say-hello-popup .form_block form div.wpcf7-validation-errors,
    .say-hello-popup .form_block form div.wpcf7-acceptance-missing,
    .say-hello-popup .form_block form div.wpcf7-mail-sent-ok {
      background: #fff;
      color: #000;
      border: none;
      margin-left: 0;
      margin-right: 0; }

.no-touch .say-hello-popup .form_block form button:hover {
  font-size: 11px; }
  .no-touch .say-hello-popup .form_block form button:hover .line_block:after {
    opacity: 1; }

/************************** Animation **************************/
.loader-spinner {
  animation: spin 0.75s infinite linear;
  background: transparent;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: #fff;
  display: block;
  height: 30px;
  margin: 15px auto;
  transition: all 0.3s ease-out;
  width: 30px;
  z-index: 30; }
  .loader-spinner:before {
    animation: spin 1.5s infinite ease;
    border: inherit;
    border-radius: inherit;
    content: '';
    display: inline-block;
    height: inherit;
    left: -2px;
    position: absolute;
    top: -2px;
    width: inherit; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.loader-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #000;
  z-index: 1; }
  .loader-wrapper img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%); }

#loaderImgMobile {
  display: none; }

@media (max-width: 767px) {
  #loaderImgMobile {
    display: block; }
  #loaderImg {
    display: none; } }

.section1 {
  position: relative;
  min-height: 100vh; }

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background: #000000; }
  .home-visited .pace {
    display: none; }

.pace .pace-progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: gray;
  transform: none !important;
  height: calc(100vh + 1px);
  background: #000;
  padding: 0; }
  .pace .pace-progress .pace-progress-inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 100%;
    min-width: 100%; }
  @media (max-width: 640px) {
    .pace .pace-progress {
      transform: scale(1.3) !important; } }
  .pace .pace-progress img {
    max-width: 100%;
    max-height: 100vh; }

.progress-text {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  font-family: "gtpressura", Helvetica, Roboto, Arial, sans-serif;
  font-size: 30px; }
  @media (max-width: 640px) {
    .progress-text {
      transform: scale(0.7);
      bottom: 15%; } }

.pace .pace-progress[data-progress="7"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="8"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="9"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="10"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="11"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="12"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="13"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="14"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="15"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="16"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="17"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="18"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="19"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="20"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="21"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="22"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="23"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="24"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="25"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="26"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="27"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="28"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="29"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="30"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="31"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="32"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="33"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="34"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="35"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="36"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="37"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="38"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="39"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="40"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="41"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="42"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="43"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="44"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="45"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="46"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="47"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="48"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="49"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="50"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="51"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="52"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="53"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="54"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="55"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="56"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="57"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="58"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="59"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="60"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="61"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="62"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="63"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="64"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="65"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="66"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="67"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="68"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="69"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="70"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="71"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="72"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="73"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="74"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="75"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="76"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="77"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="78"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="79"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="80"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="81"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="82"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="83"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="84"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="85"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="86"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="87"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="88"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="89"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="90"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="91"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="92"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="93"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="94"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="95"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="96"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="97"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="98"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(1) {
  color: #fff; }

.pace .pace-progress[data-progress="14"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="15"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="16"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="17"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="18"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="19"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="20"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="21"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="22"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="23"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="24"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="25"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="26"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="27"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="28"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="29"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="30"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="31"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="32"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="33"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="34"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="35"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="36"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="37"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="38"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="39"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="40"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="41"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="42"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="43"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="44"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="45"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="46"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="47"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="48"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="49"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="50"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="51"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="52"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="53"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="54"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="55"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="56"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="57"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="58"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="59"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="60"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="61"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="62"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="63"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="64"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="65"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="66"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="67"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="68"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="69"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="70"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="71"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="72"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="73"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="74"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="75"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="76"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="77"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="78"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="79"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="80"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="81"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="82"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="83"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="84"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="85"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="86"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="87"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="88"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="89"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="90"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="91"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="92"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="93"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="94"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="95"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="96"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="97"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="98"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(2) {
  color: #fff; }

.pace .pace-progress[data-progress="21"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="22"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="23"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="24"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="25"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="26"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="27"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="28"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="29"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="30"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="31"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="32"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="33"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="34"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="35"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="36"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="37"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="38"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="39"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="40"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="41"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="42"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="43"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="44"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="45"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="46"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="47"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="48"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="49"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="50"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="51"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="52"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="53"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="54"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="55"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="56"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="57"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="58"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="59"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="60"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="61"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="62"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="63"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="64"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="65"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="66"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="67"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="68"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="69"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="70"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="71"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="72"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="73"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="74"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="75"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="76"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="77"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="78"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="79"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="80"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="81"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="82"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="83"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="84"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="85"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="86"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="87"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="88"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="89"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="90"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="91"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="92"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="93"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="94"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="95"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="96"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="97"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="98"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(3) {
  color: #fff; }

.pace .pace-progress[data-progress="28"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="29"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="30"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="31"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="32"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="33"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="34"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="35"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="36"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="37"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="38"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="39"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="40"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="41"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="42"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="43"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="44"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="45"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="46"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="47"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="48"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="49"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="50"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="51"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="52"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="53"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="54"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="55"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="56"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="57"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="58"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="59"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="60"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="61"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="62"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="63"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="64"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="65"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="66"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="67"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="68"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="69"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="70"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="71"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="72"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="73"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="74"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="75"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="76"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="77"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="78"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="79"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="80"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="81"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="82"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="83"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="84"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="85"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="86"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="87"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="88"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="89"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="90"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="91"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="92"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="93"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="94"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="95"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="96"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="97"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="98"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(4) {
  color: #fff; }

.pace .pace-progress[data-progress="35"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="36"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="37"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="38"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="39"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="40"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="41"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="42"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="43"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="44"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="45"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="46"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="47"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="48"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="49"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="50"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="51"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="52"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="53"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="54"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="55"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="56"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="57"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="58"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="59"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="60"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="61"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="62"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="63"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="64"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="65"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="66"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="67"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="68"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="69"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="70"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="71"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="72"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="73"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="74"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="75"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="76"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="77"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="78"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="79"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="80"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="81"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="82"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="83"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="84"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="85"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="86"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="87"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="88"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="89"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="90"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="91"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="92"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="93"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="94"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="95"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="96"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="97"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="98"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(5) {
  color: #fff; }

.pace .pace-progress[data-progress="42"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="43"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="44"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="45"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="46"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="47"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="48"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="49"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="50"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="51"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="52"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="53"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="54"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="55"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="56"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="57"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="58"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="59"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="60"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="61"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="62"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="63"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="64"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="65"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="66"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="67"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="68"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="69"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="70"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="71"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="72"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="73"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="74"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="75"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="76"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="77"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="78"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="79"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="80"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="81"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="82"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="83"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="84"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="85"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="86"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="87"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="88"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="89"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="90"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="91"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="92"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="93"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="94"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="95"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="96"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="97"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="98"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(6) {
  color: #fff; }

.pace .pace-progress[data-progress="49"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="50"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="51"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="52"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="53"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="54"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="55"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="56"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="57"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="58"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="59"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="60"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="61"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="62"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="63"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="64"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="65"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="66"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="67"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="68"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="69"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="70"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="71"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="72"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="73"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="74"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="75"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="76"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="77"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="78"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="79"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="80"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="81"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="82"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="83"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="84"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="85"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="86"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="87"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="88"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="89"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="90"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="91"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="92"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="93"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="94"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="95"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="96"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="97"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="98"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(7) {
  color: #fff; }

.pace .pace-progress[data-progress="56"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="57"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="58"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="59"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="60"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="61"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="62"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="63"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="64"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="65"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="66"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="67"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="68"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="69"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="70"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="71"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="72"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="73"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="74"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="75"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="76"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="77"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="78"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="79"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="80"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="81"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="82"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="83"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="84"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="85"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="86"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="87"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="88"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="89"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="90"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="91"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="92"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="93"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="94"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="95"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="96"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="97"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="98"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(8) {
  color: #fff; }

.pace .pace-progress[data-progress="63"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="64"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="65"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="66"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="67"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="68"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="69"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="70"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="71"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="72"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="73"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="74"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="75"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="76"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="77"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="78"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="79"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="80"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="81"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="82"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="83"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="84"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="85"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="86"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="87"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="88"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="89"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="90"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="91"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="92"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="93"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="94"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="95"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="96"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="97"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="98"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(9) {
  color: #fff; }

.pace .pace-progress[data-progress="70"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="71"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="72"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="73"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="74"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="75"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="76"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="77"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="78"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="79"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="80"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="81"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="82"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="83"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="84"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="85"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="86"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="87"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="88"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="89"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="90"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="91"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="92"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="93"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="94"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="95"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="96"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="97"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="98"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(10) {
  color: #fff; }

.pace .pace-progress[data-progress="77"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="78"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="79"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="80"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="81"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="82"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="83"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="84"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="85"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="86"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="87"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="88"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="89"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="90"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="91"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="92"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="93"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="94"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="95"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="96"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="97"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="98"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(11) {
  color: #fff; }

.pace .pace-progress[data-progress="84"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="85"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="86"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="87"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="88"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="89"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="90"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="91"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="92"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="93"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="94"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="95"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="96"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="97"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="98"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(12) {
  color: #fff; }

.pace .pace-progress[data-progress="91"] .progress-text span:nth-child(13) {
  color: #fff; }

.pace .pace-progress[data-progress="92"] .progress-text span:nth-child(13) {
  color: #fff; }

.pace .pace-progress[data-progress="93"] .progress-text span:nth-child(13) {
  color: #fff; }

.pace .pace-progress[data-progress="94"] .progress-text span:nth-child(13) {
  color: #fff; }

.pace .pace-progress[data-progress="95"] .progress-text span:nth-child(13) {
  color: #fff; }

.pace .pace-progress[data-progress="96"] .progress-text span:nth-child(13) {
  color: #fff; }

.pace .pace-progress[data-progress="97"] .progress-text span:nth-child(13) {
  color: #fff; }

.pace .pace-progress[data-progress="98"] .progress-text span:nth-child(13) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(13) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(13) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(13) {
  color: #fff; }

.pace .pace-progress[data-progress="95"] .progress-text span:nth-child(14) {
  color: #fff; }

.pace .pace-progress[data-progress="96"] .progress-text span:nth-child(14) {
  color: #fff; }

.pace .pace-progress[data-progress="97"] .progress-text span:nth-child(14) {
  color: #fff; }

.pace .pace-progress[data-progress="98"] .progress-text span:nth-child(14) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(14) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(14) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(14) {
  color: #fff; }

.pace .pace-progress[data-progress="99"] .progress-text span:nth-child(15) {
  color: #fff; }

.pace .pace-progress[data-progress="100"] .progress-text span:nth-child(15) {
  color: #fff; }

.pace .pace-progress[data-progress="101"] .progress-text span:nth-child(15) {
  color: #fff; }

.pace.pace-inactive {
  display: none; }

body:not(.home-visited) {
  height: 100vh;
  overflow-y: hidden; }
  body:not(.home-visited) .site--navigation,
  body:not(.home-visited) .site--wrapper {
    opacity: 0; }

body.pace-done:not(.home-visited) {
  height: auto;
  overflow-y: auto; }
  body.pace-done:not(.home-visited) .site--navigation,
  body.pace-done:not(.home-visited) .site--wrapper {
    opacity: 1; }

html {
  touch-action: manipulation; }

.footer {
  background: #000;
  padding: 105px 15px 115px;
  clear: both; }
  @media (max-width: 360px) {
    .footer {
      padding-left: 0;
      padding-right: 0; } }
  .footer__title {
    text-align: center;
    font-size: 70px;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: -.75px;
    margin-bottom: 40px; }
    @media (max-width: 767px) {
      .footer__title {
        font-size: 61px;
        line-height: 0.9;
        margin-bottom: 42px; } }
    @media (max-width: 480px) {
      .footer__title {
        text-align: left; } }
    @media (max-width: 400px) {
      .footer__title {
        font-size: 47px; } }
    @media (max-width: 360px) {
      .footer__title {
        font-size: 37px; } }
  .footer .custom-logo-link {
    display: inline-block;
    margin-bottom: 18px; }
    @media (max-width: 767px) {
      .footer .custom-logo-link {
        order: 1;
        flex-basis: 100%;
        margin-bottom: 40px; } }
    @media (max-width: 480px) {
      .footer .custom-logo-link {
        text-align: left; } }
    .footer .custom-logo-link img {
      height: 130px;
      width: auto; }
      @media (max-width: 767px) {
        .footer .custom-logo-link img {
          height: 110px; } }
  .footer__center_block {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 880px;
    margin: 0 auto; }
    @media (max-width: 767px) {
      .footer__center_block .left_block {
        order: 3;
        flex-basis: 100%;
        margin-top: 20px; } }
    @media (max-width: 480px) {
      .footer__center_block .left_block {
        text-align: left; } }
    .footer__center_block .right_block {
      max-width: 240px; }
      @media (max-width: 767px) {
        .footer__center_block .right_block {
          order: 2;
          flex-basis: 100%; } }
      @media (max-width: 480px) {
        .footer__center_block .right_block {
          text-align: left;
          max-width: 100%; } }
    .footer__center_block ul {
      list-style: none;
      margin: 0; }
    .footer__center_block ul li,
    .footer__center_block ul li a,
    .footer__center_block p {
      color: #FFF;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 2.3px;
      line-height: 1.6; }
    .footer__center_block p:last-child {
      margin-bottom: 0; }
  .footer__socials {
    text-align: left; }
    @media (max-width: 767px) {
      .footer__socials {
        margin-top: 55px; } }
    @media (min-width: 481px) {
      .footer__socials {
        display: flex;
        justify-content: space-around;
        max-width: 277px;
        align-items: center;
        margin: 48px auto 0; } }
    .footer__socials li {
      display: inline-block; }
      @media (max-width: 480px) {
        .footer__socials li {
          margin-right: 18%; }
          .footer__socials li:last-child {
            margin-right: 0; } }
      .footer__socials li a {
        display: block;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27px;
        position: relative;
        z-index: 99; }
    .footer__socials svg {
      height: 23px;
      fill: #FFF; }
    .footer__socials a.vimeo svg {
      height: 26px; }
    .footer__socials a.vimeo:hover svg {
      height: 28px; }
    .footer__socials a:hover svg {
      height: 25px; }

.footer {
  background: #000;
  padding: 105px 15px 115px;
  clear: both; }
  @media (max-width: 360px) {
    .footer {
      padding-left: 0;
      padding-right: 0; } }
  .footer__title {
    text-align: center;
    font-size: 70px;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: -.75px;
    margin-bottom: 40px; }
    @media (max-width: 767px) {
      .footer__title {
        font-size: 61px;
        line-height: 0.9;
        margin-bottom: 42px; } }
    @media (max-width: 480px) {
      .footer__title {
        text-align: left; } }
    @media (max-width: 400px) {
      .footer__title {
        font-size: 47px; } }
    @media (max-width: 360px) {
      .footer__title {
        font-size: 37px; } }
  .footer .custom-logo-link {
    display: inline-block;
    margin-bottom: 18px; }
    @media (max-width: 767px) {
      .footer .custom-logo-link {
        order: 1;
        flex-basis: 100%;
        margin-bottom: 40px; } }
    @media (max-width: 480px) {
      .footer .custom-logo-link {
        text-align: left; } }
    .footer .custom-logo-link img {
      height: 130px;
      width: auto; }
      @media (max-width: 767px) {
        .footer .custom-logo-link img {
          height: 110px; } }
  .footer__center_block {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 880px;
    margin: 0 auto; }
    @media (max-width: 767px) {
      .footer__center_block .left_block {
        order: 3;
        flex-basis: 100%;
        margin-top: 20px; } }
    @media (max-width: 480px) {
      .footer__center_block .left_block {
        text-align: left; } }
    .footer__center_block .right_block {
      max-width: 240px; }
      @media (max-width: 767px) {
        .footer__center_block .right_block {
          order: 2;
          flex-basis: 100%; } }
      @media (max-width: 480px) {
        .footer__center_block .right_block {
          text-align: left;
          max-width: 100%; } }
    .footer__center_block ul {
      list-style: none;
      margin: 0; }
    .footer__center_block ul li,
    .footer__center_block ul li a,
    .footer__center_block p {
      color: #FFF;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 2.3px;
      line-height: 1.6; }
    .footer__center_block p:last-child {
      margin-bottom: 0; }
  .footer__socials {
    text-align: left; }
    @media (max-width: 767px) {
      .footer__socials {
        margin-top: 55px; } }
    @media (min-width: 481px) {
      .footer__socials {
        display: flex;
        justify-content: space-around;
        max-width: 277px;
        align-items: center;
        margin: 48px auto 0; } }
    .footer__socials li {
      display: inline-block; }
      @media (max-width: 480px) {
        .footer__socials li {
          margin-right: 18%; }
          .footer__socials li:last-child {
            margin-right: 0; } }
      .footer__socials li a {
        display: block;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27px;
        position: relative;
        z-index: 99; }
    .footer__socials svg {
      height: 23px;
      fill: #FFF; }
    .footer__socials a.vimeo svg {
      height: 26px; }

.no-touch .footer__socials a.vimeo:hover svg {
  height: 28px; }

.no-touch .footer__socials a:hover svg {
  height: 25px; }
